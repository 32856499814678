import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Page } from "src/components/GobalElement";
import style from "./MobileApplication.module.scss";
import mobile_first_section from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/mobile_section_first.png";
import mobile_first_Vector from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/Vector.png";
import mobile_first_icon_eco from "../../assets/eco_logo_png.png";
import section_qrcode_scan from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/section_qrcode_scan.png";
// ----
import connect_icon from "../../assets/carDetail/connect-icon.png";
import energy_icon from "../../assets/carDetail/energy-icon.png";
import safety_icon from "../../assets/carDetail/safety-icon.png";
import HeaderSectionMobileApplicationInfo from "../DetailNextGenerationAutomotivePage/HeaderSectionMobileApplicationInfo";

const MobileApplication = () => {
	const vehicle_info = {
		header: "คุณลักษณะของข้อมูลยานยนต์และยางล้อ",
		info: [
			{
				title: "เครื่องยนต์",
				content:
					"ข้อมูลจำเพาะของเครื่องยนต์ช่วยให้ทราบถึงกำลังของยานพาหนะ ประสิทธิภาพ และประสิทธิภาพการใช้เชื้อเพลิง",
			},
			{
				title: "การใช้พลังงานและการปล่อยก๊าซ",
				content:
					"ช่วยให้ผู้ใช้ที่ใส่ใจสิ่งแวดล้อมด้วยตัวเลือกที่มีการปล่อยมลพิษต่ำและปริมาณคาร์บอนฟุตพริ้นท์ที่ลดลงได้ง่ายขึ้น",
			},
			{
				title: "มาตรฐานอุตสาหกรรม",
				content:
					"ระบุยานพาหนะที่ตอบสนองข้อกำหนดเฉพาะของอุตสาหกรรมหรือหน่วยงานของรัฐ ที่ไปตามมาตรฐานสิ่งแวดล้อมและมาตราฐานความปลอดภัย",
			},
			{
				title: "ข้อมูลผู้ผลิตและนำเข้า",
				content:
					"รู้จักผู้ผลิตและผู้นำเข้าช่วยให้สามารถประเมินความน่าเชื่อถือและทำให้ผู้บริโภคสามารถตัดสินใจเลือกได้อย่างมีข้อมูลมากขึ้นโดยพิจารณาจากชื่อเสียงและประวัติผลิตภัณฑ์ของบริษัท",
			},
		],
	};
	const more_infomation_vehicle = {
		header: "ข้อมูลเพิ่มเติมสำหรับยานยนต์",
		info: [
			{
				title: "อุปกรณ์ที่มีสาระสำคัญ",
				content:
					"อุปกรณ์ด้านการเชื่อมต่ออินเดอร์เน็ต อุปกรณ์อำนวยความสะดวกด้านการใช้พลังงาน และอุปกรณ์รักษาความปลอดภัยของผู้โดยสาร",
			},
			{
				title: "อุปกรณ์ที่ติดตั้งมาจากโรงงาน",
				content:
					"ข้อมูลสิ่งอำนวยความสะดวกให้กับผู้โดยสายไม่ว่าจะเป็น ระบบเซ็นทรัลล็อก กุญแจรีโมท เครื่องปรับอากาศ ถุงลมนิรภัยด้านคนขับและคนนั่ง",
			},
		],
	};
	const infomation_scan_qrCode = {
		header: "เข้าถึงข้อมูล ECO Sticker ของยานยนต์ได้อย่างง่ายดายและรวดเร็ว",
		info: [
			{
				title: "การเข้าถึงข้อมูลที่ครอบคลุมและครบถ้วน",
				content:
					"เราภูมิใจนำเสนอฟังก์ชัน สแกน QR Code เพื่อมอบประสบการณ์การใช้งานที่สะดวกและ ล้ำสมัยให้กับผู้บริโภคที่ใส่ใจในข้อมูลด้านสิ่งแวดล้อม",
			},
			{
				title: "การแสดงข้อมูลด้านสิ่งแวดล้อมและความประหยัด",
				content:
					"ประสิทธิภาพการประหยัดน้ำมัน, การปล่อยมลพิษ, ซึ่งช่วยให้ผู้ใช้ที่ต้องการยานยนต์ที่เป็น มิตรกับสิ่งแวดล้อมสามารถตัดสินใจได้ง่ายขึ้น",
			},
			{
				title: "เพิ่มความสะดวกสบายและประหยัดเวลา",
				content:
					"เราภูมิใจนำเสนอฟังก์ชันเพื่อลดขั้นตอนที่ยุ่งยากและประหยัดเวลาในการค้นหาข้อมูล เพียงแค่สแกน QR Code ลดความซับซ้อนในการค้นหา",
			},
			{
				title: "ความน่าเชื่อถือของข้อมูล",
				content:
					"ข้อมูลที่ปรากฏใน ECO Sticker ผ่านการรับรองและจัดทำตามมาตรฐานสากล ผู้ใช้สามารถมั่นใจได้ว่าข้อมูลที่ได้รับนั้นมีความถูกต้องและได้รับการยอมรับ",
			},
		],
	};

	return (
		<Page title="Mobile Application">
			<HeaderSectionMobileApplicationInfo />
			<div className={style.container}>
				<div className={style.header_container}>
					<p>ข้อมูลยานยนต์และยางล้อ</p>
					<span>ช่วยให้ผู้ใช้สามารถค้นหารถยนต์ มอเตอร์ไซค์และยางล้อรุ่นใหม่ๆ</span>
				</div>
				<div className={style.content_mobile_container}>
					{/* section 1 vehicle_info */}
					<div className={style.vehicle_info}>
						<div className={style.vehicle_image_container}>
							<img src={mobile_first_section} />
						</div>
						<div className={style.vehicle_info_content}>
							<h3>{vehicle_info.header}</h3>
							{vehicle_info.info.map((item, index) => (
								<div key={`vehicle_info_${index}`}>
									<p>{item.title}</p>
									<span>{item.content}</span>
								</div>
							))}
						</div>
					</div>
					{/* section 2 more_infomation_vehicle */}
					<div className={style.more_infomation_vehicle_info}>
						<div className={style.vehicle_info_content}>
							<h3>{more_infomation_vehicle.header}</h3>
							{more_infomation_vehicle.info.map((item, index) => (
								<div key={`more_infomation_vehicle_${index}`}>
									<p>{item.title}</p>
									<span>{item.content}</span>
								</div>
							))}
						</div>
						<div className={style.container_more_infomation_vehicle}>
							<div className={`${style.card} ${style.card1}`}>
								<div className={style.image}>
									<img src={connect_icon} alt="connect_icon" />
								</div>
								<div className={style.content}>
									<p>ระบบเชื่อมโยง</p>
									<span>ระบบเชื่อมต่ออินเทอร์เน็ต</span>
									<span>ระบบเชื่อมต่อโทรศัพท์มือถือ</span>
									<span>ระบบปฏิบัติการ IOS และ android</span>
								</div>
							</div>

							<div className={`${style.card} ${style.card2}`}>
								<div className={style.image}>
									<img src={energy_icon} alt="energy_icon" />
								</div>
								<div className={style.content}>
									<p>ด้านการใช้พลังงาน</p>
									<span>เครื่องปรับอากาศประสิทธิภาพสูง</span>
									<span>Idling Stop</span>
									<span>Regenerative Brake</span>
									<span>วาล์วปรับไอดี</span>
									<span>ไดชาร์ตที่มีระบบความคุมการกำเนิดไฟฟ้า</span>
									<span>เครื่องยนต์</span>
								</div>
							</div>

							<div className={`${style.card} ${style.card3}`}>
								<div style={{ position: "relative", left: 20 }}>
									<div className={style.image}>
										<img src={safety_icon} alt="safety_icon" />
									</div>
								</div>

								<div className={style.content}>
									<p>ด้านความปลอดภัย</p>
									<span>ถุงลมบริเวณหัวเข่า</span>
									<span>ถุงลมนิรภัยด้านหน้าคนขับ</span>
									<span>ถุงลมนิรภัยด้านหน้าคนนั่ง</span>
									<span>ถุงลมนิรภัยแบบม่าน</span>
									<span>ถุงลมนิรภัยด้านข้าง</span>
								</div>
							</div>
						</div>
					</div>
					{/* section 3 Other convenience functions */}
					<div className={style.other_convenience}>
						<h3>ฟังก์ชันอำนวยความสะดวกอื่นๆ</h3>
						<div className={style.other_convenience_content}>
							<div className={style.other_convenience_content_item}>
								<div className={style.other_convenience_image_container}>
									<img src={mobile_first_icon_eco} />
								</div>
								<p>ป้ายข้อมูลยานยนต์ตามมาตรฐานสากล ECO Sticker</p>
								<span>
									"ป้ายข้อมูล" ที่ช่วยให้ผู้บริโภคสามารถเลือกซื้อยานยนต์ที่เป็นมิตรต่อสิ่งแวดล้อม
									และมีประสิทธิภาพในการใช้พลังงานตามมาตรฐานสากล
								</span>
							</div>
							<div className={style.other_convenience_content_item}>
								<div className={style.other_convenience_image_container}>
									<img src={mobile_first_Vector} />
								</div>
								<p>ให้บริการข้อมูลผ่านการดาวน์โหลดเอกสารโบรชัวร์</p>
								<span>
									สามารถศึกษาเพิ่มเติมเกี่ยวกับคุณสมบัติและรายละเอียดต่าง ๆ ของรถยนต์แต่ละรุ่น
									รวมถึงรายละเอียดด้านการออกแบบและสิ่งอำนวยความสะดวกภายในรถ
								</span>
							</div>
						</div>
					</div>

					{/* last section */}
				</div>
				<div className={style.vehicle_info}>
					<div className={style.vehicle_info_content}>
						<div>
							<h3 style={{ color: "#339F00", margin: 0 }}>ฟังก์ชันการสแกน QR Code</h3>
							<h3>{infomation_scan_qrCode.header}</h3>
						</div>

						{infomation_scan_qrCode.info.map((item, index) => (
							<div key={`infomation_scan_qrCode_${index}`}>
								<p>{item.title}</p>
								<span>{item.content}</span>
							</div>
						))}
					</div>
					<div className={style.vehicle_image_qrCode_section_container}>
						<img src={section_qrcode_scan} />
					</div>
				</div>
			</div>
		</Page>
	);
};

export default MobileApplication;
