import React, { useRef } from 'react'
import img_header from "../../assets/detailnextgenerationautomotive/landing_page_header.png"
import img_lead_acid_battery from "../../assets/detailnextgenerationautomotive/lead_acid_battery_bg.jpeg"
import img_nickel_metal_hydride from "../../assets/detailnextgenerationautomotive/nickel_metal_hydride_Battery_bg.jpeg"
import img_lithium_ion from "../../assets/detailnextgenerationautomotive/lithium_ion_bg.jpeg"
import img_solid_state_battery from "../../assets/detailnextgenerationautomotive/solid_state_battery_bg.jpeg"
import img_sodium_ion_battery from "../../assets/detailnextgenerationautomotive/sodium_ion_battery_bg.png"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BatteryDetailModal from '../../components/BatteryDetailModal'
import icon_bolt_energy from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_bolt_energy.png'
import icon_age from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_age.png'
import icon_battery from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_battery.png'
import icon_battery_quarter from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_battery_quarter.png'
import icon_chart_line_up from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_chart_line_up.png'
import icon_feather_pointed from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_feather_pointed.png'
import icon_plant_care from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_plant_care.png'
import icon_plug_circle_bolt from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_plug_circle_bolt.png'
import icon_recycle_bin from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_recycle_bin.png'
import icon_selling from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_selling.png'
import icon_shield_check from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_shield_check.png'
import icon_temperature_low from '../../assets/detailnextgenerationautomotive/iconBatteryDetail/icon_temperature_low.png'

// Lead Acid Battery
const data_detail_battery_type = [
    {
        second_title:"Lead Acid Battery",
        head_title:"แบตเตอรี่ชนิดตะกั่วกรด",
        img_background:img_lead_acid_battery,
        col_span:2,
        text_white:false,
        content:[
            {
                title:"แบตเตอรี่แบบ Lead Acid (แบตเตอรี่ตะกั่ว-กรด)",
                description:"เป็นเทคโนโลยีที่มีมาอย่างยาวนานและมีการใช้งานในหลายอุตสาหกรรม รวมถึงในรถยนต์สันดาปภายในทั่วไป อย่างไรก็ตาม สำหรับรถยนต์ไฟฟ้า (EV) แบตเตอรี่ Lead Acid มักไม่ได้ถูกใช้เป็นแบตเตอรี่หลักในการขับเคลื่อนรถยนต์ ด้วยเหตุผลหลัก ๆ ดังนี้",
                type:"PARAGRAPH",
                advantages:[]
            },
            {
                title:"",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_bolt_energy,
                        title:"พลังงานต่อหน่วยน้ำหนักต่ำ",
                        description:"พลังงานต่อหน่วยน้ำหนักต่ำ: แบตเตอรี่ Lead Acid มีพลังงานสะสมต่อหน่วยน้ำหนักที่ต่ำ กว่าแบตเตอรี่ประเภทอื่น เช่น ลิเธียม-ไอออน (Lithium-Ion) ทำให้ไม่เหมาะกับการใช้งานใน รถ EV ที่ต้องการความสามารถในการวิ่งได้ใน ระยะทางไกลและน้ำหนักเบา",
                    },
                    {
                        icon:icon_age,
                        title:"Life-Cycle",
                        description:"วงจรชีวิต (Life-Cycle) สั้น: แบตเตอรี่ Lead Acid มี cycle life ที่สั้น ส่งผลให้ต้องมีการเปลี่ยนแบตเตอรี่บ่อยครั้ง",
                    },
                    {
                        icon:icon_battery,
                        title:"การชาร์จช้ากว่า",
                        description:"การชาร์จช้ากว่า: แบตเตอรี่ Lead Acid ใช้เวลาชาร์จนานกว่า ซึ่งไม่เหมาะสมกับความต้องการของผู้ใช้รถ EV ที่ต้องการความสะดวกและรวดเร็วในการ ชาร์จ",
                    },
                ]
            },
            {
                title:"",
                description:"ทั้งนี้ แบตเตอรี่ Lead Acid ยังมีการใช้งานในระบบรถ EV ในบางกรณี เช่น การใช้เป็น แบตเตอรี่เสริม (auxiliary battery) ซึ่งใช้สำหรับระบบไฟฟ้าอื่น ๆ ภายในรถ เช่น ระบบไฟส่องสว่าง หรือระบบสื่อสาร เนื่องจากต้นทุนต่ำและความสามารถในการรีไซเคิลได้สูง",
                type:"PARAGRAPH",
                advantages:""
            },
        ]
    },
    {
        second_title:"Nickel-metal Hydride Battery / Ni-MH",
        head_title:"แบตเตอรี่ชนิดนิกเกิล-เมทัลไฮไดรด์",
        img_background:img_nickel_metal_hydride,
        col_span:2,
        text_white:true,
        content:[
            {
                title:"แบตเตอรี่ชนิด นิกเกิล-เมทัลไฮไดรด์ (Nickel-metal Hydride Battery / Ni-MH)",
                description:"เคยเป็นหนึ่งในเทคโนโลยีที่ใช้ในรถยนต์ไฟฟ้า (EV) และรถยนต์ไฮบริด (Hybrid) โดยเฉพาะในช่วงเริ่มต้นของการพัฒนาเทคโนโลยียานยนต์ไฟฟ้า เนื่องจากมีคุณสมบัติดังนี้",
                type:"PARAGRAPH",
                advantages:[]
            },
            {
                title:"ข้อดีของแบตเตอรี่ Ni-MH",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_battery_quarter,
                        title:"ความทนทานสูง",
                        description:"แบตเตอรี่ Ni-MH มีความทนทานต่อการใช้ งานในระยะยาวและสามารถรองรับจำนวนรอบการชาร์จ-การคายประจุได้มากกว่าแบตเตอรี่ ชนิด Lead Acid",
                    },
                    {
                        icon:icon_shield_check,
                        title:"ปลอดภัยและเสถียร",
                        description:"แบตเตอรี่ชนิดนี้มีความเสถียรด้านความปลอดภัยสูงกว่าแบตเตอรี่ลิเธียม-ไอออน โดยไม่ค่อยมีปัญหาด้านความร้อนหรือไฟไหม้เมื่อเกิดความเสียหาย",
                    },
                    {
                        icon:icon_plant_care,
                        title:"เป็นมิตรกับสิ่งแวดล้อมมากกว่า",
                        description:"การรีไซเคิลแบตเตอรี่ Ni-MH เป็นไปได้ง่ายกว่าเมื่อเทียบกับลิเธียม-ไอออน และยังมีผลกระทบต่อสิ่งแวดล้อมน้อยกว่า",
                    },
                ]
            },
            {
                title:"ข้อเสียของแบตเตอรี่ Ni-MH",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_bolt_energy,
                        title:"พลังงานต่อหน่วยน้ำหนักต่ำกว่า",
                        description:"แม้ว่า Ni-MH จะมีความจุพลังงานมากกว่า แบตเตอรี่ Lead Acid แต่ก็ยังต่ำกว่าแบตเตอรี่ลิเธียม-ไอออน",
                    },
                    {
                        icon:icon_plug_circle_bolt,
                        title:"การคายประจุเองสูง",
                        description:"Ni-MH มีปัญหาการคายประจุเอง (self-discharge) ที่ค่อนข้างสูงเมื่อเทียบกับ แบตเตอรี่ลิเธียม-ไอออน นั่นหมายความว่า พลังงานจะลดลงเร็วขึ้นแม้ไม่ใช้งาน",
                    },
                ]
            },
        ]
    },
    {
        second_title:"Lithium Ion / Li-ion",
        head_title:"แบตเตอรี่ ลิเธียมไอออน",
        img_background:img_lithium_ion,
        col_span:2,
        text_white:true,
        content:[
            {
                title:"แบตเตอรี่ ลิเธียมไอออน (Lithium Ion / Li-ion)",
                description:"เป็นเทคโนโลยีแบตเตอรี่ที่ได้รับการใช้งานอย่างแพร่หลายในรถยนต์ไฟฟ้า (EV) และอุปกรณ์อิเล็กทรอนิกส์ส่วนใหญ่ เนื่องจากมีประสิทธิภาพสูงและความสามารถในการเก็บพลังงานที่มีประสิทธิภาพ",
                type:"PARAGRAPH",
                advantages:[]
            },
            {
                title:"ข้อดีของแบตเตอรี่ Li-ion",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_bolt_energy,
                        title:"พลังงานต่อหน่วยน้ำหนักสูง",
                        description:"แบตเตอรี่ลิเธียมไอออนมีความจุพลังงานสูงต่อหน่วยน้ำหนัก ทำให้เหมาะสมกับการใช้งานในรถ EV ที่ต้องการประสิทธิภาพสูงในการเดินทางระยะไกลต่อการชาร์จครั้งเดียว",
                    },
                    {
                        icon:icon_age,
                        title:"วงจรชีวิต (Life-Cycle) ยาวนาน",
                        description:"แบตเตอรี่ Li-ion มีวงจรชีวิตที่ยาวนาน โดยสามารถชาร์จและคายประจุได้หลายร้อยถึงหลายพันครั้งก่อนที่ประสิทธิภาพจะเริ่มลดลง",
                    },
                    {
                        icon:icon_feather_pointed,
                        title:"น้ำหนักเบา",
                        description:"เนื่องจากแบตเตอรี่ Li-ion มีน้ำหนักเบาเมื่อเทียบกับแบตเตอรี่ชนิดอื่น เช่น Lead Acid หรือ Ni-MH จึงช่วยลดน้ำหนักของรถยนต์ EV และเพิ่มประสิทธิภาพการใช้พลังงาน",
                    },
                    {
                        icon:icon_battery,
                        title:"ประสิทธิภาพการชาร์จรวดเร็ว",
                        description:"แบตเตอรี่ Li-ion สามารถชาร์จได้รวดเร็ว โดยเฉพาะเมื่อใช้ระบบชาร์จเร็ว (fast charging) ซึ่งเหมาะกับการใช้งานในชีวิต ประจำวันของผู้ใช้รถ EV ที่ต้องการความ สะดวกในการชาร์จ",
                    },
                    {
                        icon:icon_plug_circle_bolt,
                        title:"การคายประจุต่ำ (Low self-discharge)",
                        description:"แบตเตอรี่ Li-ion มีการคายประจุต่ำมากเมื่อเทียบกับเทคโนโลยีอื่น นั่นหมายความว่าแบตเตอรี่จะยังคงพลังงานอยู่ได้ยาวนานแม้ไม่ได้ใช้งาน",
                    },
                ]
            },
            {
                title:"ข้อเสียของแบตเตอรี่ Li-ion",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_selling,
                        title:"ต้นทุนการผลิตสูง",
                        description:"แบตเตอรี่ Li-ion ยังคงมีต้นทุนการผลิตสูงกว่าแบตเตอรี่ชนิดเก่า เช่น Lead Acid หรือ Ni-MH",
                    },
                    {
                        icon:icon_temperature_low,
                        title:"ความไวต่ออุณหภูมิ",
                        description:"แบตเตอรี่ Li-ion อาจมีปัญหาด้านประสิทธิภาพเมื่ออยู่ในสภาวะอุณหภูมิสูงหรือต่ำเกินไป",
                    },
                    {
                        icon:icon_recycle_bin,
                        title:"ข้อจำกัดในการรีไซเคิล",
                        description:"แบตเตอรี่ Li-ion ยังคงมีข้อจำกัดในการรีไซเคิล เนื่องจากกระบวนการที่ซับซ้อนและการใช้วัสดุหายาก เช่น โคบอลต์ ซึ่งเป็นปัญหาสำหรับการ จัดการทรัพยากรอย่างยั่งยืน",
                    },
                ]
            },
        ]
    },
    {
        second_title:"Solid State Battery",
        head_title:"แบตเตอรี่ชนิดโซลิดสเตต",
        img_background:img_solid_state_battery,
        col_span:3,
        text_white:false,
        content:[
            {
                title:"แบตเตอรี่โซลิดสเตต (Solid State Battery)",
                description:"เป็นเทคโนโลยีแบตเตอรี่รุ่นใหม่ที่กำลังได้รับความสนใจอย่างมากในอุตสาหกรรมรถยนต์ไฟฟ้า (EV) เนื่องจากมีศักยภาพในการพัฒนาและปรับปรุงประสิทธิภาพจากแบตเตอรี่ลิเธียมไอออน (Li-ion) แบบเดิม โดยมีการใช้ อิเล็กโทรไลต์ของแข็ง แทนที่อิเล็กโทรไลต์เหลวในแบตเตอรี่ Li-ion ซึ่งทำให้แบตเตอรี่โซลิดสเตตมีคุณสมบัติที่โดดเด่นในหลายด้าน",
                type:"PARAGRAPH",
                advantages:[]
            },
            {
                title:"ข้อดีของแบตเตอรี่ Solid State Battery",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_bolt_energy,
                        title:"พลังงานต่อหน่วยน้ำหนักสูงกว่า",
                        description:"แบตเตอรี่โซลิดสเตตมีความสามารถในการเก็บพลังงานได้สูงกว่าต่อหน่วยน้ำหนักและปริมาตร ซึ่งหมายความว่ารถยนต์ EV ที่ใช้แบตเตอรี่ชนิดนี้จะสามารถวิ่งได้ระยะทางที่ไกลขึ้นหรือใช้แบตเตอรี่ที่มีขนาดเล็กลงแบตเตอรี่ชนิดนี้มีความเสถียรด้านความปลอด",
                    },
                    {
                        icon:icon_shield_check,
                        title:"ความปลอดภัยสูง",
                        description:"การใช้อิเล็กโทรไลต์ของแข็งช่วยลดความเสี่ยงในการเกิดไฟลุกไหม้และการลัดวงจรเมื่อเกิดความเสียหาย ซึ่งเป็นปัญหาที่พบในแบตเตอรี่ Li-ion ที่ใช้อิเล็กโทรไลต์เหลวซึ่งไวต่ออุณหภูมิ",
                    },
                    {
                        icon:icon_age,
                        title:"อายุการใช้งานยาวนานกว่า",
                        description:"แบตเตอรี่โซลิดสเตตมีวงจรชีวิตที่ยาวนานกว่า เนื่องจากอิเล็กโทรไลต์ของแข็งมีการเสื่อมสภาพน้อยกว่าอิเล็กโทรไลต์เหลว ทำให้สามารถชาร์จและคายประจุได้หลายรอบกว่าโดยที่ประสิทธิภาพไม่ลดลงอย่างรวดเร็ว",
                    },
                    {
                        icon:icon_battery,
                        title:"ชาร์จเร็วกว่า",
                        description:"โซลิดสเตตแบตเตอรี่อาจรองรับการชาร์จไฟอย่างรวดเร็ว (fast charging) ได้ดีกว่าแบตเตอรี่ Li-ion แบบเดิม เนื่องจากการไหลของประจุผ่านอิเล็กโทรไลต์ของแข็งทำได้มีประสิทธิภาพมากกว่า",
                    },
                ]
            },
            {
                title:"ข้อเสียของแบตเตอรี่ Solid State Battery",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_selling,
                        title:"ต้นทุนการผลิตสูง",
                        description:"แบตเตอรี่โซลิดสเตตยังอยู่ในขั้นตอนการวิจัยและพัฒนา ต้นทุนการผลิตยังสูงอยู่",
                    },
                ]
            },
        ]
    },
    {
        second_title:"Sodium Ion Battery / Na-Ion Battery",
        head_title:"แบตเตอรี่ชนิด โซเดียม-ไอออน",
        img_background:img_sodium_ion_battery,
        col_span:3,
        text_white:false,
        content:[
            {
                title:"แบตเตอรี่ชนิด โซเดียม-ไอออน (Sodium Ion Battery / Na-Ion Battery)",
                description:"เป็นเทคโนโลยีแบตเตอรี่ทางเลือกใหม่ที่กำลังได้รับความสนใจ เนื่องจากแบตเตอรี่โซเดียมไอออนใช้ โซเดียม (Na) ซึ่งเป็นธาตุที่มีอยู่มากมายและราคาถูกกว่า ลิเธียม (Li) ที่ใช้ในแบตเตอรี่ลิเธียมไอออน (Li-ion)",
                type:"PARAGRAPH",
                advantages:[]
            },
            {
                title:"ข้อดี Sodium Ion Battery / Na-Ion Battery",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_selling,
                        title:"วัตถุดิบต้นทุนต่ำ",
                        description:"โซเดียมมีอยู่ในปริมาณที่มากและราคาถูกกว่าเมื่อเทียบกับลิเธียมซึ่งหายากและมีราคาแพง ซึ่งเป็นข้อได้เปรียบใหญ่ในการลดต้นทุนการผลิตแบตเตอรี่",
                    },
                    {
                        icon:icon_shield_check,
                        title:"ความปลอดภัยที่สูงกว่า",
                        description:"โซเดียมมีเสถียรภาพทางเคมีที่สูงกว่า ทำให้แบตเตอรี่โซเดียมไอออนมีความปลอดภัยมากกว่าแบตเตอรี่ลิเธียมไอออน มีความเสี่ยงต่ำที่จะเกิดไฟลุกไหม้หรือลัดวงจร",
                    },
                    {
                        icon:icon_temperature_low,
                        title:"ทำงานได้ดีในอุณหภูมิต่ำ",
                        description:"ทำงานได้ดีในอุณหภูมิต่ำ: แบตเตอรี่โซเดียมไอออนมีประสิทธิภาพในการทำงานที่ดีในสภาวะอุณหภูมิต่ำ ซึ่งเป็นจุดเด่นที่เหนือกว่าแบตเตอรี่ลิเธียมไอออนที่มักจะมีปัญหาในสภาพแวดล้อมที่เย็นจัด",
                    },
                    {
                        icon:icon_plant_care,
                        title:"การใช้งานที่เป็นมิตรต่อสิ่งแวดล้อม",
                        description:"วัตถุดิบที่ใช้ในแบตเตอรี่โซเดียมไอออน เช่น โซเดียม มีผลกระทบต่อสิ่งแวดล้อมน้อยกว่าเมื่อเทียบกับการขุดและสกัดลิเธียมและโคบอลต์ที่ใช้ในแบตเตอรี่ลิเธียมไอออน",
                    },
                ]
            },
            {
                title:"ข้อเสียของแบตเตอรี่ Li-ion",
                description:"",
                type:"LIST_ADVANTAGES",
                advantages:[
                    {
                        icon:icon_bolt_energy,
                        title:"พลังงานต่อหน่วยน้ำหนักต่ำกว่า",
                        description:"มีพลังงานต่อหน่วยน้ำหนัก (energy density) ต่ำกว่าแบตเตอรี่ลิเธียมไอออน ซึ่งหมายความว่ารถยนต์ EV ที่ใช้แบตเตอรี่ชนิดนี้จะวิ่งได้ระยะทางสั้นลงต่อการชาร์จหนึ่งครั้ง",
                    },
                    {
                        icon:icon_age,
                        title:"วงจรชีวิตสั้นกว่า",
                        description:"แบตเตอรี่โซเดียมไอออนมีวงจรชีวิตที่สั้นกว่าแบตเตอรี่ลิเธียมไอออน ซึ่งหมายถึงการเสื่อมสภาพเร็วขึ้น และอาจต้องเปลี่ยนแบตเตอรี่บ่อยกว่าในระยะยาว",
                    },
                    {
                        icon:icon_chart_line_up,
                        title:"การพัฒนาอยู่ในขั้นต้น",
                        description:"เทคโนโลยีแบตเตอรี่โซเดียมไอออนยังอยู่ในขั้นตอนการวิจัยและพัฒนา ทำให้ยังไม่ได้รับการผลิตเชิงพาณิชย์อย่างแพร่หลายเท่าแบตเตอรี่ลิเธียมไอออน",
                    },
                ]
            },
        ]
    },
]

const ElectricVehicleBattery=()=> {
    const battery_detail_modal = useRef(null);

    const CardBatteryType =({
        key,
        second_title,
        head_title,
        col_span,
        background_image,
        text_white,
        data
    })=>{

        if (!key || !second_title || !head_title || !col_span || !background_image || !data) {
            console.error("Missing props in CardBatteryType:", { key, second_title, head_title, col_span, background_image, data });
        }    
        
        return(
            <div 
            key={key} 
            style={{
                gridColumn:`span ${col_span}`,
            }}
            className={
                `relative overflow-hidden 
                md:!col-span-1 h-[504px] rounded-[24px] flex flex-col 
                justify-between p-[32px] hover:cursor-pointer 
                !shadow-[0_8px_16px_0_rgba(0,0,0,0.15)] group`}
                onClick={()=>{
                    if (battery_detail_modal?.current) {
                        battery_detail_modal.current.onOpen();
                        battery_detail_modal.current.setData(data);
                    }
                }}
            >
                        <div className="relative z-20">
                            <p className={`text-[18px] ${text_white?"text-white":"text-[#828282]"}`}>
                                {second_title}
                            </p>
                            <p className={`text-[38px] font-semibold ${text_white?"text-white":"text-[#333333]"}`}>
                                {head_title}
                            </p>
                        </div>
    
                        <div className="relative w-[40px] h-[40px] rounded-full overflow-visible bg-white self-end z-20">
                            <AddCircleIcon sx={{ fontSize: 52, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div> 


                        <div 
                            className="absolute inset-0 bg-cover bg-center h-full w-full bg-gradient-to-b from-[#ffffff92] to-[#ffffff00] z-10" 
                        ></div>
    
                        <div 
                        className="absolute inset-0 bg-cover bg-center transition-transform duration-400 ease-in-out transform group-hover:scale-110" 
                        style={{ backgroundImage: `url(${background_image})` }}
                        ></div>
            </div>
        )
    }
    

  return (
    <div className="flex flex-col w-full justify-center items-center ">
        <BatteryDetailModal ref={battery_detail_modal}/>

        {/* header */}
        <div
                className="w-full h-[443px] bg-cover bg-center flex justify-center  relative "
                style={{
                    backgroundImage: `url(${img_header})`,
                }}
            >
                <p className="text-[72px] font-light text-center text-white absolute top-[98px] sm:top-[80px]">เทคโนโลยี<br className="hidden md:block"/>ยานยนต์ไฟฟ้า</p>


        </div>
        <div className="flex flex-col py-[56px] px-[48px] h-fit bg-white rounded-2xl gap-2 shadow-lg max-w-[1400px] z-20 mt-[-150px] md:m-3 md:mt-[-150px]  ">
                <div className="flex items-center gap-5  md:items-start sm:flex-col">
                    <p className="text-[18px] font-normal text-[#333333] ">เทคโนโลยียานยนต์ไฟฟ้า</p>
                    <div className="w-[73px] h-[2px] bg-[#333333] ml-[60px] flex self-center sm:ml-0 sm:self-start"></div>
                </div>


                <div className="grid grid-cols-2 md:grid-cols-1 md:gap-[33px]">
                    <p className="text-[38px] ">
                        เทคโนโลยีของ<span className="text-[#339F00]">แบตเตอรี่</span><br />ยานยนต์ไฟฟ้า<br />{" "}
                        
                    </p>
                    <p className="text-[18px] text-[#828282]">แบตเตอรี่ชนิดตะกั่วกรด(Lead Acid Battery) ,แบตเตอรี่ชนิดนิกเกิล-เมทัลไฮไดรด์ (Nickel-metal Hydride Battery / Ni-MH) 
                        ,แบตเตอรี่ ลิเธียมไอออน (Lithium Ion / Li-ion) ,แบตเตอรี่ชนิดโซลิดสเตต (Solid State Battery) 
                        ,แบตเตอรี่ชนิด โซเดียม-ไอออน (Sodium Ion Battery / Na-Ion Battery)</p>
                </div>

        </div>

        {/* section battery type */}

        <div className="mt-[80px] w-full px-[24px] max-w-[1400px] mb-[70px]">
            <div className="w-[102px] h-[9px] bg-[#339F00]"></div>
            <p className="text-[36px] font-semibold mt-[20px] text-[#333333]" >
                ชนิดแบตเตอรี่
            </p>
            <div className="grid gap-[32px] grid-cols-6 grid-rows-2 w-full mb-[30px] mt-[56px] md:grid-cols-1">
                {data_detail_battery_type.map((item,index)=>(
                    <CardBatteryType 
                        key={index}
                        second_title={item.second_title}
                        head_title={item.head_title}
                        col_span={item.col_span}
                        background_image={item.img_background}
                        text_white={item.text_white}
                        data={item}
                    />
                )
                )}
            </div>
        </div>
        
    </div>
  )
}

export default ElectricVehicleBattery