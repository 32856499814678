import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const CardConnectorTypes = ({ title, subTitle, des, img }) => {
  return (
    <Card
      className="!rounded-2xl shadow-none hover:!bg-none !flex flex-col items-center justify-center h-[235px] !cursor-default "
      elevation={0}
    >
      <CardMedia
        component="img"
        image={img}
        alt={`icon ${title}`}
        className="!size-[56px]"
      />
      <CardContent className="flex flex-col text-center">
        <span className="text-2xl text-[#333333]">{title}</span>
        {<span className="text-2xl text-[#333333]">{subTitle}</span> ?? ""}
        <span className="text-base text-[#333333]">{des}</span>
      </CardContent>
    </Card>
  );
};

export default CardConnectorTypes;
