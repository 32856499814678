import { Divider } from "@mui/material"
import React from "react"

export default function CardHEV({ title, subTitle, img }) {
    return (
        <div className="flex flex-col w-[280px] h-[251px] p-3 shadow-md rounded-2xl items-center">
            <div className="flex flex-col gap-[4px] items-center w-full pb-[6px]">
                <p className="text-[#333333] text-[24px]">{title}</p>
                <p className=" text-[#828282] text-[14px]">{subTitle}</p>
                <Divider className="w-full h-[1px] bg-[#828282]" />
            </div>
            <img src={img} alt="hev Car" />

        </div>
    )
}