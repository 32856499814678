import React from "react";
import SectionBEVContent from "./SectionBEVContent";
import SectionHEVContent from "./SectionHEVContent";
import SectionPHEVContent from "./SectionPHEVContent";
import CardCarSlideItem from "src/components/GlobalComponents/CardCarSlide";
import TimelineMenu from "src/components/GlobalComponents/TimelineMenu";
import SectionFCEVContent from "./SectionFCEVContent";
import ElectricVehicleInfoSectionHEV from "./ElectricVehicleInfoSectionHEV";
import ElectricVehicleInfoSectionPHEV from "./ElectricVehicleInfoSectionPHEV";
import ElectricVehicleInfoSectionBEV from "./ElectricVehicleInfoSectionBEV";
import ElectricVehicleInfoSectionFCEV from "./ElectricVehicleInfoSectionFCEV";

const ElectricCarVehicleContent = () => {
	return (
		<div>
			<ElectricVehicleInfoSectionHEV />
			<CardCarSlideItem car_type="ไฮบริด (HEV)" />
			<ElectricVehicleInfoSectionPHEV />
			<CardCarSlideItem car_type="ปลั๊กอินไฮบริด" />
			<ElectricVehicleInfoSectionBEV />
			<CardCarSlideItem car_type="พลังงานไฟฟ้า" />
			<ElectricVehicleInfoSectionFCEV />
		</div>
	);
};

export default ElectricCarVehicleContent;
