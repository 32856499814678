import { Container, Grid, Stack, Switch } from "@mui/material";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { Page } from "../components/GobalElement";
import HeaderPage from "../components/HeaderPage";
import HeaderTopic from "../components/HeaderTopic";
import SearchInput from "../components/SearchInput";
import styles from "./ComparisonPage.module.scss";
import { styled } from "@mui/material/styles";

import ListCar from "../components/ListCar";
import CompareBar from "../components/CompareBar";
import FilterCar from "../components/FilterCar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDataCarList } from "../helper/allAPI";
import CarListLoading from "../components/loading/CarListLoading";
import { request } from "../helper/Axios";
import SortFilter from "../components/SortFilter";
import { serializeSortToUrl } from "../helper/ArrayAndObjectHelper";
import NotFound from "../components/NotFound";
import { usePrevious, usePreviousWithDep } from "../hooks/usePreviousState";
import Paginations from "../components/Paginations";

const EngineCarSwitch = styled(Switch)(({ theme }) => ({
	padding: 8,
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,
		"&:before, &:after": {
			content: '""',
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			width: 16,
			height: 16,
		},
		"&:before": {
			left: 12,
		},
		"&:after": {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12,
		},
	},
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			color: "#339f00",
			"& + .MuiSwitch-track": {
				opacity: 1,
				color: "#339f00",
				backgroundColor: "#fff",

				// backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "none",
		width: 16,
		height: 16,
		margin: 2,
	},
}));

const ComparisonPage = memo(() => {
	const [isLoading, setIsLoading] = useState(true);
	const [searchParams] = useSearchParams();
	const [page, setPage] = useState(searchParams.get("page") || 1);
	const [row, setRow] = useState(searchParams.get("row") || 30);
	const [car, setCar] = useState({});
	const [openFilter, setOpenFilter] = useState(false);
	const [openSort, setOpenSort] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState({});
	const [selectedSort, setSelectedSort] = useState({});
	const [inputText, setInputText] = useState(searchParams.get("search") || "");

	const prevSelectedFilter = usePrevious(selectedFilter);
	const prevSelectedSort = usePreviousWithDep(selectedSort);
	const prevInputText = usePreviousWithDep(inputText);
	const navigate = useNavigate();

	const [checked, setChecked] = useState(false);
	const [filter, setFilter] = useState({});

	const handleSearch = (text) => {
		setInputText(text);
	};

	const handleCompare = () => {
		navigate("/car-comparison-table");
	};

	const fetchDataCar = async () => {
		try {
			let filters = new URLSearchParams({
				page,
				row,
				search: inputText,
				sort: serializeSortToUrl(selectedSort),
				...selectedFilter,
			}).toString();
			setTimeout(async () => {
				navigate(`?${filters}`);
				const res = await getDataCarList(filters);
				setCar(res?.data?.data);
				setIsLoading(false);
			}, 1000);
		} catch ({ response }) {
			setIsLoading(false);
			// alert(JSON.stringify(response))
		}
	};

	const handleSelectOption = (e) => {
		if (e.target.checked) {
			if (selectedFilter?.engine_name) {
				let temp = selectedFilter?.engine_name;
				temp.push("พลังงานไฟฟ้า".trim());
				setSelectedFilter({ ...selectedFilter, engine_name: temp });
			} else {
				let temp = [];
				temp.push("พลังงานไฟฟ้า".trim());
				setSelectedFilter({ ...selectedFilter, engine_name: [...temp] });
			}
		} else {
			if (selectedFilter.engine_name?.includes("พลังงานไฟฟ้า")) {
				let temp = selectedFilter.engine_name;
				temp = temp.filter((e) => e !== "พลังงานไฟฟ้า");
				setSelectedFilter({ ...selectedFilter, engine_name: temp });
			}
		}
	};

	useEffect(() => {
		// if (
		// 	JSON.stringify(prevSelectedFilter) !== JSON.stringify(selectedFilter) ||
		// 	JSON.stringify(prevSelectedSort) !== JSON.stringify(selectedSort) ||
		// 	prevInputText != inputText
		// ) {
		// 	setIsLoading(true);
		// 	setPage(1);
		// 	setCar({});
		// 	fetchDataCar();
		// 	statusCheck();
		// }
		setIsLoading(true);
		setPage(1);
		setCar({});
		fetchDataCar();
		statusCheck();
	}, [selectedFilter, selectedSort, inputText]);

	const statusCheck = () => {
		if (selectedFilter.engine_name?.includes("พลังงานไฟฟ้า")) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		setCar({});
		fetchDataCar();
		return () => {
			if (request.length > 0) {
				request[0]("Change page");
			}
		};
	}, [page, row]);

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(1);
		setRow(event.target.value);
	};

	return (
		<Page title="เปรียบเทียบรถยนต์">
			<HeaderPage classmName={styles.headOverride}>
				<div className={styles.headContainer}>
					<SearchInput onSearch={handleSearch} textSearch={inputText} />
				</div>
			</HeaderPage>
			<HeaderTopic topic="เปรียบเทียบรถยนต์" />
			<div className={styles.pageContent}>
				<Container>
					<Stack
						direction={{ xs: "column", sm: "row", md: "row" }}
						alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
						justifyContent={{ xs: "center", sm: "center", md: "space-around" }}
						sx={{ marginBottom: 4 }}
						spacing={{ xs: 1, sm: 1, md: 5 }}
					>
						<div className={styles.pageTopItem} onClick={() => setOpenFilter(true)}>
							<i className="fa-solid fa-filter"></i>กรองข้อมูล
						</div>
						<div className={styles.pageTopItem} onClick={() => setOpenSort(true)}>
							<i className="fa-solid fa-sort"></i>การจัดเรียง
						</div>
						<div className={classNames(styles.pageTopItemSwicth, styles.fontSuccess)}>
							<EngineCarSwitch
								sx={{ marginRight: 0.5 }}
								checked={checked}
								onChange={handleSelectOption}
							/>
							รถยนต์พลังงานไฟฟ้า
						</div>
					</Stack>
					{isLoading ? (
						<CarListLoading key={0} />
					) : (
						<>
							{car?.car_list?.length > 0 ? (
								<>
									<Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 1, sm: 2, md: 3 }}>
										{car.car_list.map((data, index) => (
											<ListCar key={index} data={data} />
										))}
									</Grid>
									<Paginations
										page={parseInt(page)}
										rowsPerPage={parseInt(row)}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
										totalRows={car?.info?.total_rows}
										totalPages={car?.info?.total_pages}
									/>
								</>
							) : (
								<NotFound detail="ไม่พบข้อมูล" goBack={false} sm />
							)}
						</>
					)}
				</Container>
			</div>
			<CompareBar handleComparison={handleCompare} />
			<FilterCar
				open={openFilter}
				filterList={selectedFilter}
				onSelect={setSelectedFilter}
				onCloseFilter={setOpenFilter}
			/>
			<SortFilter open={openSort} sorted={selectedSort} onSelect={setSelectedSort} onCloseSort={setOpenSort} />
		</Page>
	);
});

export default ComparisonPage;
