import React from "react";
import styles from "./MainNextGenerationAutomotivePage.module.scss";
import png from "../assets/nextGeneration/main.png";
import caseImage from "../assets/nextGeneration/case.png";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import evcar from "../assets/nextGeneration/ev_car.png";
import measureSupport from "../assets/nextGeneration/measure_support.png";
import techEvCar from "../assets/nextGeneration/tech_ev_car.png";
// import versionCar from "../assets/nextGeneration/version_car.png";
import versionCar from "../assets/detailnextgenerationautomotive/battery_ev.png";
import pressent from "../assets/nextGeneration/pressent.png";
import { useNavigate } from "react-router-dom";

const MainNextGenerationAutomotivePage = () => {
	const LIST_ITEM = [
		{
			title: "ยานยนต์ที่ขับเคลื่อนด้วยมอเตอร์ไฟฟ้า",
			description:
				"เรียนรู้ความหมายยานยนต์ที่ขับเคลื่อนด้วยมอเตอร์ไฟฟ้าไม่ว่าจะเป็น รถยนต์ไฟฟ้าแบตเตอรี่ รถยนต์ไฮบริด เป็นต้น",
			image: `url(${evcar})`,
			url: "electric-car-vehicle",
		},
		{
			title: "มาตรการสนับสนุน",
			description:
				"การสนับสนุนและการส่งเสริมการใช้ยานยนต์ไฟฟ้า เพื่อเตรียมความพร้อมในการก้าวเข้าสู่สังคมยานยนต์สมัยใหม่",
			image: `url(${measureSupport})`,
			url: "management-support-standard",
		},
		{
			title: "เทคโนโลยีของแบตเตอรี่ยานยนต์ไฟฟ้า",
			description: "เรียนรู้กระบวนการการพัฒนาและรูปแบบของเทคโนโลยีที่ใช้กับยานยนต์ไฟฟ้า",
			image: `url(${versionCar})`,
			url: "electric-vehicle-battery",
		},
		{
			title: "เทคโนโลยีการชาร์จและประเภทหัวชาร์จ",
			description: "ยานยน์ไฟฟ้าที่จัดจำหน่ายในปัจจุบัน ที่อยู่ในประเทศหรือกำลังเป็นที่นิยม",
			image: `url(${techEvCar})`,
			url: "charging-connector-types",
		},
		// {
		//     title: "สถานการณ์ในปัจจุบัน",
		//     description: "ทิศทางและแนวโน้มการใช้งานรถไฟฟ้าในปัจจุบัน สำหรับการวิเคราะห์และคาดการณ์",
		//     image: `url(${pressent})`
		// }
	];

	return (
		<div>
			<div className={styles.banner}>
				<div className={styles.containerBanner}>
					<div className={styles.circle}></div>
					<div className={styles.titles}>ยานยนต์สมัยใหม่</div>
					<div className={styles.subtitles}>มุ่งสู่ความเป็นเลิศด้านนวัตกรรม “ยานยนต์สมัยใหม่”</div>
				</div>
			</div>
			<div className={styles.containerArea}>
				<div className={styles.container}>
					<img src={png} alt="main" className={styles.main} />
					<div className={styles.subtitles}>
						<span>ยานยนต์สมัยใหม่</span> เป็นการพัฒนาต่อยอดการผลิตยานยนต์แบบเครื่องยนต์สันดาปภายใน และ
						เครื่องยนต์แบบไฮบริดที่ใช้เป็นยานพาหนะสำหรับการขนส่ง โดยยานยนต์สมัยใหม่ได้พัฒนาต่อยอดด้วยการนำ
						เทคโนโลยีขั้นสูงที่ผ่านการพัฒนาอย่างรวดเร็วในช่วงทศวรรษที่ผ่านมา
						มาประกอบเป็นยานยนต์ที่เป็นมิตรต่อ
						สิ่งแวดล้อมโดยการใช้เครื่องยนต์ขับเคลื่อนด้วยพลังงานไฟฟ้าผนวกรูปแบบการสั่งการใช้งานภายในรถยนต์
						ที่หลากหลาย ซึ่งมีลักษณะคล้ายคลึงกับเทคโนโลยีที่นำมาประกอบเป็นโทรศัพท์อัจฉริยะ (Smart Phone) 
						ที่ไม่ใช่การใช้ประโยชน์เพียงการโทรเข้า-ออก หรือส่งข้อความแบบตัวอักษรเหมือนในอดีต แต่สามารถกระทำ
						หลายสิ่งหลายอย่างได้ในโทรศัพท์เพียงเครื่องเดียว เช่น การถ่ายภาพและวีดีโอ การใช้งานอินเตอร์เน็ต 
						การพูดคุยผ่านวีดีโอ การสั่งการเครื่องใช้ไฟฟ้า การแสกนเอกสาร และอื่น ๆ อีกมากมาย
					</div>
				</div>
				<div className={styles.container}>
					<img src={caseImage} alt="case" className={styles.case} />
					<div className={styles.subtitles2}>
						โดยคาดการณ์ว่าแนวโน้มการพัฒนายานยนต์สมัยใหม่หรือยานยนต์อัจฉริยะจะถูกพัฒนาใน 4 เรื่องหลัก ได้แก่
						รถยนต์ไฟฟ้าแบตเตอรี่ (Battery Electric Vehicle) รถยนต์ขับขี่ อัตโนมัติ (Autonomous Vehicle)
						รถยนต์เชื่อมต่อกับภายนอก (Connected Vehicle) และการแบ่งปันการ ใช้รถยนต์ (Car Sharing)
						หรือเรียกรวมกันว่า CASE
					</div>
				</div>
				<div className={styles.container}>
					<div className={styles.subtitles3}>
						อุตสาหกรรมยานยนต์สมัยใหม่ (NEXT-GENERATION AUTOMOTIVE) เป็น 1 ใน 5 อุตสาหกรรมเดิม ที่มีศักยภาพ
						(First S-curve) เนื่องจากอุตสาหกรรมยานยนต์ถือเป็นรากฐานสำคัญในการพัฒนาทางเศรษฐกิจ
						ของประเทศไทยกว่า 40 ปีที่ผ่านมา และได้รับการกล่าวถึงอย่างมากจากผู้ประกอบการยานยนต์ทั่วโลก
					</div>
				</div>
				<div style={{ marginTop: 20 }}>
					<div className={styles.bannerCase}>
						<div className={styles.backgroundCase}></div>
						<img alt="case" src={caseImage} className={styles.caseImage} />
					</div>
					<div className={styles.textContainer}>
						<div>ที่มารูป https://www.daimler.com/case/en/</div>
						<div>ที่มาเนื้อหา รายงานสถานการณ์อุตสาหกรรมยานยนต์สมัยใหม่ สู่เป้าหมายเศรษฐกิจยั่งยืน</div>
						<div>ศูนย์ข้อมูลอัจฉริยะด้านเศรษฐกิจอุตสาหกรรม (Industrial Intelligence Unit : IIU) สศอ.</div>
					</div>
				</div>

				<div className={styles.interestTopic}>
					<div className={styles.title}>หัวข้อที่น่าสนใจ</div>
					{/* <Box sx={{ bgcolor: "red", display: "flex", alignItems: "center" }}> */}
					<Grid container spacing={4} sx={{ py: 3 }}>
						{LIST_ITEM.map((item, index) => (
							<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
								<CardTopic
									content={item.description}
									title={item.title}
									image={item.image}
									url={item.url}
								/>
							</Grid>
						))}
					</Grid>
					{/* </Box> */}
				</div>
			</div>
		</div>
	);
};

const CardTopic = ({ content, title, image, url }) => {
	const navigate = useNavigate();

	const fnHandleNavigate = () => {
		if (url) {
			navigate(url);
		}
	};

	return (
		<Card
			onClick={fnHandleNavigate}
			sx={{
				maxWidth: 345,
				borderRadius: "12px",
				height: 260,
				userSelect: "none",
				cursor: url && "pointer",
				"&:hover": {
					transform: url && "scale(1.02)",
					transition: "0.2s all ease",
				},
			}}
		>
			<Box
				className={styles.cardImage}
				sx={{
					backgroundImage: image,
					filter: url ? "grayscale(0)" : "grayscale(0.8)",
					opacity: url ? 1 : 0.6,
				}}
			>
				<div className={styles.cardTitle}>{title}</div>
			</Box>
			<CardContent>
				<div style={{ fontSize: 14, color: "#868686" }}>{content}</div>
			</CardContent>
		</Card>
	);
};

export default MainNextGenerationAutomotivePage;
