import React, { useState, useEffect, useRef } from "react";
import styles from "./CardCarSlide.module.scss";
import { Stack } from "@mui/material";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import CarDefault from "../../assets/car_draw.png";
import { numberWithCommas } from "src/helper/Number";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import { getDataCarList, getDataMotorList } from "src/helper/allAPI";
import CarListLoading from "../loading/CarListLoading";

const ListCar = ({ data, car_type }) => {
	return (
		<div className={styles.listBox}>
			<div className={styles.listImgBox}>
				<img src={data.logo_url ? data.logo_url : CarDefault} className={styles.listImg} />
			</div>
			<div className={styles.listContent}>
				<span className={styles.listContentBrand}>{data.brand}</span>
				<span className={styles.listContentModel}>{data.model}</span>
				<span className={styles.listContentPrice}>ราคา {numberWithCommas(data.recomend_retail_price)} บาท</span>
				<span className={styles.listContentPrice}>ผู้ผลิต/ผู้นำเข้า:</span>
				<span className={styles.listContentPrice}>{data.company_name}</span>
				{car_type !== "motor" && (
					<Stack direction="row" marginTop={"10px"}>
						<Link
							to={`/detail/${data.id}`}
							className={classNames(styles.listContentBtn, styles.listBtnDetail)}
						>
							รายละเอียด
						</Link>
					</Stack>
				)}
			</div>
		</div>
	);
};

const CardCarSlideItem = ({ car_type = "พลังงานไฟฟ้า", isEv = false }) => {
	const [car, setCar] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fnFetchDataCar();
	}, []);

	const fnFetchDataCar = async () => {
		try {
			let filters = new URLSearchParams({
				row: 10,
				engine_name: car_type,
			}).toString();
			let filters_ev = new URLSearchParams({
				row: 10,
				ev_name: car_type,
			}).toString();
			let filters_motor = new URLSearchParams({
				row: 10,
				ev_name: "ev3",
			}).toString();
			setTimeout(async () => {
				const res =
					car_type === "motor"
						? await getDataMotorList(filters_motor)
						: await getDataCarList(isEv ? filters_ev : filters);
				if (car_type === "motor") {
					setCar(res?.data?.value?.motor_list);
				} else {
					setCar(res?.data?.data?.car_list);
				}
				setIsLoading(false);
			}, 1000);
		} catch ({ response }) {
			setIsLoading(false);
		}
	};

	return (
		<div>
			{car.length <= 4 ? (
				<div className={styles.listBoxSmall}>
					{car.map((item, index) => (
						<div key={index} className={styles.listBox_motor}>
							<div className={styles.listImgBox}>
								<img src={item.logo_url ? item.logo_url : CarDefault} />
							</div>
							<div className={styles.listContent}>
								<div className={styles.listText}>
									<span className={styles.listContentBrand}>{item.brand}</span>
									<span className={styles.listContentModel}>{item.model}</span>
									<span className={styles.listContentPrice}>
										ราคา {numberWithCommas(item.recomend_retail_price)} บาท
									</span>
									<span className={styles.listContentPrice}>ผู้ผลิต/ผู้นำเข้า:</span>
									<span className={styles.listContentPrice}>{item.company_name}</span>
								</div>

								{car_type !== "motor" && (
									<Stack direction="row" marginTop={"10px"}>
										<Link
											to={`/detail/${item.id}`}
											className={classNames(styles.listContentBtn, styles.listBtnDetail)}
										>
											รายละเอียด
										</Link>
									</Stack>
								)}
							</div>
						</div>
					))}
				</div>
			) : (
				<Splide
					options={{
						type: "loop",
						drag: "free",
						preloadPages: 4,
						focus: 0,
						perPage: 5,
						rewind: true,
						pagination: false,
						arrows: false,
						gap: "1rem",
						autoScroll: {
							speed: 1,
						},
						breakpoints: {
							1400: { perPage: 4 },
							1000: { perPage: 3 },
							490: { perPage: 2 },
							390: { perPage: 1 },
						},
					}}
					extensions={{ AutoScroll }}
				>
					{isLoading ? (
						<Stack width={"100%"} alignItems={"center"} justifyContent={"center"}>
							<CarListLoading />
						</Stack>
					) : (
						car?.map((item, index) => (
							<SplideSlide style={{ padding: "2rem 0" }} key={index}>
								<ListCar car_type={car_type} data={item} />
							</SplideSlide>
						))
					)}
				</Splide>
			)}
		</div>
	);
};

export default CardCarSlideItem;
