import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const BatteryDetailModal = forwardRef(function BatteryDetailModal(_, ref) {
    const [open_modal, setOpenModal] = useState(false)
    const [data, setData] = useState(
        {
            second_title: "",
            head_title: "",
            img_background: "",
            col_span: 0,
            text_white: false,
            content: [],
        }
    );

    useImperativeHandle(ref, () => ({
        onOpen: () => setOpenModal(true),
        setData: (data) => setData(data),
    }));

    return (
        <>
            <Modal
                open={open_modal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="battery-detail-modal-title"
                aria-describedby="battery-detail-modal-description"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: "blur(40px)",
                    overflowY: 'auto',
                }}
                hideBackdrop
            >
                <>
                    <div className="absolute top-[135px] left-1/2 transform -translate-x-1/2  w-full max-w-[1140px] px-[16px] z-[200]">
                        <div className="bg-white p-[16px] rounded-[24px] mb-[40px]">
                            <div className="w-full relative flex flex-col items-start mb-[30px]">
                                <p id="battery-detail-modal-title" className="text-[18px]">{data.second_title}</p>
                                <p id="battery-detail-modal-description" className="text-[36px] font-semibold">
                                    {data.head_title}
                                </p>
                                <CancelIcon
                                    sx={{ fontSize: 42, position: 'absolute', top: '-4px', right: '-4px', }}
                                    className="cursor-pointer"
                                    onClick={() => setOpenModal(false)}
                                />
                            </div>

                            <div className="flex flex-col gap-[16px] w-full">
                                {data.content?.map((item) => (
                                    <>
                                        <div className="bg-[#F8F9FA] flex flex-col rounded-[24px] p-[24px] w-full items-center">

                                            {
                                                item.type === "PARAGRAPH" &&
                                                <div className='w-full max-w-[900px] text-start'>
                                                    <p id="battery-detail-modal-description" className="text-[18px]">{item.title}</p>
                                                    <p id="battery-detail-modal-description" className="text-[18px] text-[#828282]">{item.description}</p>
                                                </div>
                                            }


                                            {item.type === "LIST_ADVANTAGES" &&
                                                <div className='w-full max-w-[900px] flex flex-col items-center gap-[40px]'>
                                                    {item.title.length !== 0 && <p className="text-[26px] text-center">{item.title}</p>}

                                                    {item.advantages.map((item) => (
                                                        <div className='w-full max-w-[450px] flex flex-col items-center gap-[12px]'>

                                                            <img src={item.icon} className="w-[52px] h-[52px]" />
                                                            <p className="text-[18px] text-center">
                                                                {item.title}
                                                            </p>
                                                            <p className="text-[16px] text-center">
                                                                {item.description}
                                                            </p>
                                                        </div>
                                                    )
                                                    )}

                                                </div>
                                            }
                                        </div>
                                    </>
                                )
                                )}
                            </div>


                        </div>
                    </div>
                    {
                        open_modal && <div
                            className='sticky top-0 bg-[rgba(0,0,0,0.3)] -z-[1] h-[100dvh] w-full flex'
                            onClick={() => setOpenModal(false)}
                        >

                        </div>
                    }
                </>
            </Modal>
        </>

    )
})

export default BatteryDetailModal