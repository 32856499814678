import React from "react";
import img_header from "../../assets/detailnextgenerationautomotive/landing_page_header.png";
import { Divider } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import CardHEV from "src/components/SupportMeasureOverview/CardHEV";
import img_hev from "../../assets/detailnextgenerationautomotive/HEV.png";
import img_hev_2 from "../../assets/detailnextgenerationautomotive/HEV-2.png";
import img_hev_3 from "../../assets/detailnextgenerationautomotive/HEV-3.png";
import hybrid_car from "../../assets/detailnextgenerationautomotive/hybrid-car.png";
import CarES from "../../assets/detailnextgenerationautomotive/car_es.png";
import Background from "../../assets/detailnextgenerationautomotive/bg_support_measure.jpeg";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

export default function ElectricVehicleInfoSectionHEV() {
	return (
		<div className="flex flex-col w-full justify-center items-center">
			<div
				className="w-full h-[443px] bg-cover bg-center flex justify-center  relative "
				style={{
					backgroundImage: `url(${img_header})`,
				}}
			>
				{/* screen xl-md */}
				<p className="text-[72px] font-light text-white text-center absolute top-[98px] block sm:hidden">
					ยานยนต์ที่ขับเคลื่อน
					<br className="hidden md:inline" />
					ด้วยมอเตอร์ไฟฟ้า
				</p>
				{/* screen sm */}
				<p className="text-[72px] font-light text-center text-white absolute top-[0px] hidden sm:block ">
					ยานยนต์ที่ขับ
					<br />
					เคลื่อนด้วย
					<br />
					มอเตอร์ไฟฟ้า
				</p>
			</div>

			<div className="flex flex-col py-[56px] px-[48px] h-fit bg-white rounded-2xl gap-2 shadow-lg max-w-[1400px] z-20 mt-[-130px] md:m-3 md:mt-[-130px]  ">
				<div className="flex items-center gap-5 sm:flex-col sm:items-start">
					<p className="text-[18px] font-normal text-[#333333] w-[300px] sm:w-fit">เทคโนโลยียานยนต์ไฟฟ้า</p>
					<div className="w-[73px] h-[2px] bg-[#333333]"></div>
				</div>

				<div className="grid grid-cols-2 md:grid-cols-1 md:gap-[33px]">
					<p className="text-[38px] ">
						ยานยนต์ที่ขับเคลื่อนด้วย{" "}
						<span className="text-[#339F00] whitespace-nowrap"> มอเตอร์ไฟฟ้า(xEV)</span>
					</p>
					<p className="text-[18px] text-[#828282]">
						หมายถึงยานยนต์ที่มีการใช้มอเตอร์ไฟฟ้าในการขับเคลื่อน ทั้งนี้ <br className="hidden sm:inline" />
						อาจจะมีการใช้มอเตอร์ไฟฟ้าควบคู่ ไปกับเครื่องยนต์ หรือที่เรียกว่ารถยนต์ HEV
						<br className="hidden sm:inline" /> (Hybrid Electric Vehicle) ที่มีการใช้อย่างแพร่หลาย
						ในปัจจุบัน โดยรถยนต์ <br className="hidden sm:inline" /> xEVสามารถแบ่งได้เป็น 4 ประเภท ดังนี้
					</p>
				</div>
			</div>

			{/* contents */}
			<div className="flex flex-col w-full max-w-[1400px] pt-[72px] md:px-6">
				<div className="flex flex-col items-start gap-5">
					<Divider className="w-[102px] h-[9px] bg-[#559B2D]" />

					<p className="text-[#333333] text-[46px] font-semibold">
						HEV :{" "}
						<span className="text-[#828282] font-normal">
							Hybrid
							<br className="hidden sm:inline" /> Electric Vehicle
						</span>
					</p>

					<div>
						<p className="text-[#333333] text-2xl">
							ประกอบด้วยเครื่องยนต์เป็นต้นกำลังในการขับเคลื่อนหลักและทำงานร่วมกับมอเตอร์ไฟฟ้า
						</p>
						<p className="text-[#333333] text-2xl">
							เพื่อเพิ่มกำลังของยานยนต์ให้เคลื่อนที่ทำให้เครื่องยนต์มี
							<span className="text-[#339F00]">ประสิทธิภาพสูงขึ้น</span>{" "}
							จึงมีความสิ้นเปลืองเชื้อเพลิงต่ำกว่ายานยนต์ปกติ
						</p>
						<p className="text-[#333333] text-2xl">
							กำลังที่ผลิตจากเครื่องยนต์และมอเตอร์ไฟฟ้าทำให้
							<span className="text-[#339F00]">อัตราเร่งของรถ HEV สูง</span>{" "}
							กว่ารถยนต์สันดาปที่มีขนาดใกล้เคียงกัน
						</p>
					</div>
				</div>

				{/* card */}
				<div className="flex w-full justify-center py-[64px]">
					<Splide
						className="w-full items-center flex justify-center [&>div]:w-full"
						options={{
							drag: false,
							focus: 0,
							perPage: 3,
							breakpoints: {
								768: {
									perPage: 2,
									drag: "free",
								},
								488: {
									perPage: 1,
									drag: "free",
									padding: "2.5rem",
								},
							},
							omitEnd: true,
							arrows: false,
							width: 1000,
						}}
					>
						<SplideSlide className="flex justify-center w-fit" style={{ padding: "2rem 0" }}>
							<CardHEV title={"Series"} subTitle={"HEV"} img={img_hev} />
						</SplideSlide>
						<SplideSlide className="flex justify-center w-fit" style={{ padding: "2rem 0" }}>
							<CardHEV title={"Parallel"} subTitle={"HEV"} img={img_hev_2} />
						</SplideSlide>

						<SplideSlide className="flex justify-center w-fit" style={{ padding: "2rem 0" }}>
							<CardHEV title={"Series - Parallel"} subTitle={"HEV"} img={img_hev_3} />
						</SplideSlide>
					</Splide>
				</div>

				{/* text */}
				<div className="flex justify-center pt-0">
					<p className="text-[#828282] text-2xl w-[900px] md:w-full text-center">
						รวมทั้งยังสามารถนำพลังงานกลที่เหลือหรือไม่ใช้ประโยชน์เปลี่ยนเป็นพลังงานไฟฟ้าเก็บในแบตเตอรี่
						เพื่อใช้เป็นแหล่งพลังงานในการขับเคลื่อนต่อไปได้ ซึ่งสามารถแบ่งได้เป็น 3 ประเภทหลักๆ ได้แก่{" "}
						<span className="text-[#333333]">Series HEV, Parallel HEV และSeries-Parallel HEV</span>{" "}
						ดังแสดงในภาพข้างต้น
					</p>
				</div>

				<div className="flex py-[40px] gap-6 items-center md:flex-col">
					<div className="flex flex-col items-center gap-3">
						<img src={hybrid_car} className="max-w-[664px] max-h-[389px] sm:max-w-[440px]" />
						<a
							href="https://afdc.energy.gov/vehicles/how-do-hybrid-electric-cars-work"
							target="_blank"
							rel="noopener noreferrer"
							className="text-[#0D6EFD] text-[14px] underline text-center"
						>
							ที่มา: U.S. Department of Energy (DOE)
						</a>
					</div>
					<div className="flex flex-col gap-[10px]">
						<p className="text-[#333333] text-[40px]">
							โดยที่หลักการทำงานของทั้ง 3 <br className="sm:hidden" />
							แบบจะมีความแตกต่างกัน
						</p>
						<p className="text-[#828282] text-[24px]">
							Series HEV <br />
							คือรถที่ใช้เครื่องในการสร้างกระแสไฟฟ้าเพื่อจ่ายให้มอเตอร์ในการขับเคลื่อน เช่น NissanKicks
							E-power ส่วน Parallel HEV และSeries-Parallel HEV อาจจะเรียกรวมๆ ว่า Full Hybrid
							ซึ่งรถยนต์สามารถเคลื่อนที่ได้ด้วยเครื่องยนต์ หรือ มอเตอร์ไฟฟ้า(EV-mode) หรือ ทั้ง 2
							อย่างทำงานพร้อมกัน (power-mode)
						</p>
					</div>
				</div>

				<div className="bg-gradient-to-b from-[#ffffff00] to-[#ffffff] mt-[30px]">
					<div className="flex flex-col w-full items-center justify-center h-[400px] relative">
						<div className="flex flex-col w-full items-center justify-center gap-[10px]">
							<p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">
								รถยนต์ <span className="text-[#339F00] ">HEV</span>
							</p>

							<div className="bg-[#333333] rounded-full flex justify-center items-center h-[52px] w-[52px] z-50 cursor-pointer">
								<ArrowDownwardRoundedIcon className="text-white" sx={{ width: 34, height: 34 }} />
							</div>
						</div>

						<div className="absolute h-[260px] w-full overflow-hidden flex flex-col top-1/2 -translate-y-1/2 ">
							<img src={CarES} alt="motorcycle" className="w-[630px] self-end absolute left-[55%]" />
						</div>

						<div
							className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60"
							style={{
								backgroundImage: `url(${Background}`,
								backgroundSize: "cover",
								WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
								WebkitMaskComposite: "multiply",
								maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
								maskComposite: "intersect",
							}}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
}
