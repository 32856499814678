import React from "react";
import MeasuresPromoteEV from "src/components/SupportMeasureOverview/MeasuresPromoteEV";
import JoinESCar from "src/components/SupportMeasureOverview/JoinESCar";
import EV3Measures from "src/components/SupportMeasureOverview/EV3measures";

const SupportMeasureOverview = () => {
  return (
    <div className="w-full">
      <MeasuresPromoteEV />
      <EV3Measures />
      <JoinESCar />
    </div>
  );
};

export default SupportMeasureOverview;
