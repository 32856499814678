import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CardChargingTypes = ({
  titleTh,
  titleEn,
  detail,
  img,
  subTitle,
  imgDetail,
}) => {
  return (
    <div className="h-[894px] w-[375px] flex items-center pb-8 rounded-2xl flex-col shadow-[0px_8px_16px_0px_#00000026]">
      <div className="-translate-y-8 flex justify-center flex-col items-center text-center">
        <div className="w-[194px] h-[140px] flex justify-center items-center mb-[14px]">
          <img src={img} alt="Charging Car Station" />
        </div>

        <div className="gap-1 flex flex-col justify-center">
          <div className="text-xl leading-6">{titleTh}</div>
          <div className="text-base leading-4 text-[#828282] text-nowrap">
            {titleEn}{" "}
          </div>
          <div>
            <span className="text-[#95BF47]">ลักษณะการใช้งาน: </span>{" "}
            <span className="text-[#333333] text-md">{subTitle}</span>
          </div>
        </div>
      </div>

      <div className="flex h-full flex-col items-center justify-evenly">
        <div className="flex flex-col items-center leading-5 text-base gap-1">
          <div className="text-[#333333] flex flex-col items-center text-center mb-[48px]">
            <img
              src={imgDetail?.iconBolt}
              alt="Charging Car Station"
              className="w-[52px] h-[56px] mb-[12px]"
            />
            <span className="text-xl mb-[12px]">แรงดันไฟฟ้า</span>
            <span className="text-md">{detail?.voltageDetail}</span>
          </div>
          <div className="text-[#333333] flex flex-col items-center text-center mb-[48px]">
            <img
              src={imgDetail?.iconAge}
              alt="Charging Car Station"
              className="w-[56px] h-[56px] mb-[12px]"
            />
            <span className="text-xl mb-[12px]">กำลังไฟฟ้า</span>
            <span className="text-md">{detail?.electricalPowerDetail}</span>
          </div>
          <div className="text-[#333333] flex flex-col items-center text-center mb-[36px]">
            <img
              src={imgDetail?.iconChargingTime}
              alt="Charging Car Station"
              className="w-[56px] h-auto mb-[12px]"
            />
            <span className="text-xl mb-[12px]">เวลาชาร์จ</span>
            <span className="text-md">{detail?.chargingTime}</span>
          </div>
        </div>

        <div className="flex flex-col leading-5 text-base px-8">
          {detail?.strengthDetail !== "none" &&
          detail?.weaknessDetail !== "none" ? (
            <>
              <div className="flex items-start mb-3">
                <CheckIcon className="text-[#339F00] !text-[14px]" />
                <div>ข้อดี: {detail?.strengthDetail}</div>
              </div>
              <div className="flex items-satart">
                <CloseIcon className="text-[#339F00] !text-[14px]" />
                <div>ข้อเสีย: {detail?.weaknessDetail}</div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CardChargingTypes;
