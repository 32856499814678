import React from "react";
import CardTimeline from "./CardTimeline";
import CardEV from "./CardEV";
import PassengerCar from "../../assets/detailnextgenerationautomotive/passenger_car.svg";
import PickupCar from "../../assets/detailnextgenerationautomotive/pickup_car.svg";
import ElectricMotorcycle from "../../assets/detailnextgenerationautomotive/electric_motorcycle.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./EV3Measures.scss";

const EV3Measures = () => {

  return (
    <div className="w-full flex justify-center items-center flex-col px-6  py-12 bg-gradient-to-b from-[#F8F9FA] to-white">
      <div className="w-full max-w-[1600px] flex flex-col items-center gap-14">
      <div className="text-[38px] text-[#333333]">มาตรการ EV3.0</div>
      <div className="text-[20px] leading-[30px] text-[#828282] text-center max-w-[1000px]">
        มาตรการ EV3.0 อนุญาตให้ผู้ประกอบการนำเข้ารถยนต์ไฟฟ้าจากต่างประเทศ
        เพื่อเข้ามาทดลองตลาดในประเทศไทยได้ในช่วงปี 2022 – 2023 โดยจะได้รับ
        สิทธิประโยชน์ ดังนี้
      </div>

      <Splide
        className="w-full items-center flex justify-center [&>div]:w-full"
        options={{
          drag: false,
          focus: 0,
          perPage: 3,
          breakpoints: {
            768: {
              perPage: 2,
              drag: "free"
            },
            488: {
              perPage: 1,
              drag: "free",
              padding: "2.5rem"
            }
          },
          omitEnd: true,
          arrows: false,
          width: 1000
        }}
      >
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardEV
            titleTh={"รถยนต์นั่ง"}
            titleEn={"(Passenger Car)"}
            img={PassengerCar}
            detail={{
              batterySizeLess: "น้อยกว่า 30 kWh",
              batterSubsidyLess: "เงินอุดหนุน 70,000 บาท",
              batterySizeMore: "มากกกว่า 30 kWh",
              batterSubsidyMore: "เงินอุดหนุน 150,000 บาท",
              exciseTax: 2,
              customTax: 40,
              price: "สำหรับราคาไม่เกิน 2 ล้านบาท"
            }}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardEV
            titleTh={"รถยนต์กระบะ"}
            titleEn={"(Pickup Car)"}
            subTitle={"สำหรับที่ผลิตในประเทศ"}
            img={PickupCar}
            detail={{
              batterySizeMore: "มากกกว่า 30 kWh",
              batterSubsidyMore: "เงินอุดหนุน 150,000 บาท",
              exciseTax: 0,
              customTax: "none",
              price: "สำหรับราคาไม่เกิน 2 ล้านบาท"
            }}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardEV
            titleTh={"มอเตอร์ไซค์ไฟฟ้า"}
            titleEn={"(Electric Motorcycle)"}
            subTitle={"สำหรับที่ผลิตในประเทศ"}
            img={ElectricMotorcycle}
            detail={{
              batterySizeLess: "ไม่มีข้อกำหนด",
              batterSubsidyLess: "เงินอุดหนุน 18,000 บาท",
              exciseTax: 0,
              customTax: "none",
              price: "สำหรับราคาไม่เกิน 1.5 แสนบาท"
            }}
          />
        </SplideSlide>
      </Splide>

      <div className="text-center max-w-[1000px] text-[18px] leading-9">
        ทั้งนี้ ผู้ประกอบการต้องมีการผลิตชดเชยในประเทศไทย
        ตามจำนวนที่นำเข้ามาในช่วงปี 2022 – 2023 ตามสัดส่วนที่กำหนด คือ
        จะต้องผลิตชดเชยในอัตรา 1:1 หากมีการผลิตในปี 2024 และในอัตรา 1:1.5
        หากผลิตในปี 2025
        รวมทั้งจำเป็นจะต้องมีการใช้ชิ้นส่วนที่สำคัญของยานยนต์ไฟฟ้าในประเทศภายในปีที่กำหนด
        ดังนี้
      </div>

      <Splide
        className="w-full items-center flex justify-center [&>div]:w-full"
        options={{
          drag: false,
          focus: 0,
          perPage: 5,
          breakpoints: {
            768: {
              perPage: 3,
              drag: "free"
            },
            488: {
              perPage: 1,
              drag: "free",
              padding: "5rem"
            }
          },
          omitEnd: true,
          arrows: false,
          width: 1200
        }}
      >
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardTimeline
            title={"ปี 2024"}
            detail={"จะต้องผลิตชดเชยในอัตรา 1:1"}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardTimeline
            title={"ปี 2025"}
            detail={"จะต้องผลิตชดเชยในอัตรา 1:5"}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardTimeline
            title={"ปี 2026"}
            detail={"ต้องมีการใช้แบตเตอรี่ที่ผลิตในประเทศ"}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardTimeline
            title={"ปี 2030"}
            detail={"ต้องมีการใช้ PCU Inverter ที่ผลิตในประเทศ"}
          />
        </SplideSlide>
        <SplideSlide
          className="flex justify-center w-fit"
          style={{ padding: "2rem 0" }}
        >
          <CardTimeline
            title={"ปี 2035"}
            detail={"ต้องมีการใช้ชิ้นส่วนอื่น ๆ ในประเทศ เช่น Traction Motor"}
            hiddenArrow
          />
        </SplideSlide>
      </Splide>
      </div>
      
    </div>
  );
};

export default EV3Measures;
