export const MENU_LIST_LEFT = [
	// {
	// 	title: "สติ๊กเกอร์",
	// 	path: "/sticker",
	// 	icon: "fa-solid fa-note-sticky",
	// },
	// {
	// 	title: "รอบรู้เรื่อง “ECO Sticker”",
	// 	path: "/article",
	// 	icon: "fa-solid fa-align-left",
	// },
	// {
	// 	title: "ยานยนต์สมัยใหม่",
	// 	path: "/next-generation-automotive",
	// 	icon: "fa-solid fa-newspaper",
	// },
	// {
	// 	title: "ข่าว/ประกาศ",
	// 	path: "/news",
	// 	icon: "fa-solid fa-newspaper",
	// },
	// {
	// 	title: "เกี่ยวกับเรา",
	// 	path: "/about-us",
	// 	icon: "fa-solid fa-building",
	// },
];

export const MENU_LIST_RIGHT = [
	// {
	// 	title: "ยานยนต์สมัยใหม่",
	// 	path: "/next-generation-automotive",
	// 	icon: "fa-solid fa-newspaper",
	// },
	// {
	// 	title: "ข่าว/ประกาศ",
	// 	path: "/news",
	// 	icon: "fa-solid fa-newspaper",
	// },
	// {
	// 	title: "เกี่ยวกับเรา",
	// 	path: "/about-us",
	// 	icon: "fa-solid fa-building",
	// },
    {
		title: "สติ๊กเกอร์",
		path: "/sticker",
		icon: "fa-solid fa-note-sticky",
	},
	{
		title: "รอบรู้เรื่อง “ECO Sticker”",
		path: "/article",
		icon: "fa-solid fa-align-left",
	},
	{
		title: "ยานยนต์สมัยใหม่",
		path: "/next-generation-automotive",
		icon: "fa-solid fa-newspaper",
	},
	{
		title: "ข่าว/ประกาศ",
		path: "/news",
		icon: "fa-solid fa-newspaper",
	},
	{
		title: "เกี่ยวกับเรา",
		path: "/about-us",
		icon: "fa-solid fa-building",
	},
];
