import React from "react";
import image_fcev_car from "../../assets/detailnextgenerationautomotive/image_fcev_car.png";

const ElectricVehicleInfoSectionFCEV = () => {
	return (
		<div className="flex flex-col items-center overflow-hidden">
			{/* title and subtitle */}
			<div className="px-[1.5rem] pt-[3rem] max-w-[1400px]">
				<div className="h-[9px] w-[102px] bg-[#4a6938] mb-5" />
				<div>
					<span className="text-[#333333] text-[38px] mb-[20px] ">FCEV : </span>
					<span className="text-[#828282] text-[38px] mb-[20px] ">Fuel Cell Electric Vehicle</span>
				</div>
				<div className="flex flex-col md:px-0 mt-[1.23rem]">
					<span className="text-[#333333] text-2xl leading-10">
						Fuel Cell Electric Vehicle คือ <br />
						ยานยนต์ไฟฟ้าประเภทหนึ่งที่ไม่มีการปลดปล่อยมลพิษขณะใช้งาน โดยรถยนต์ FCEV
						จะใช้พลังงานไฟฟ้าในการขับเคลื่อน แต่แตกต่างจากรถยนต์ BEV ตรงที่รถยนต์ FCEV นั้น จะใช้{" "}
						<span className="text-[#339F00] ">พลังงานไฟฟ้าจากเซลล์เชื้อเพลิง (Fuel Cell)</span>{" "}
						ที่สามารถผลิตพลังงานไฟฟ้าได้โดยตรงจากไฮโดรเจน
						โดยอาจจะมีแบตเตอรี่ขนาดเล็กเพื่อคอยเก็บกักพลังงานขณะที่มีการเบรก (Regenerative braking)
						และจะใช้พลังงานจากแบตเตอรี่ในจังหวะคับขัน เช่น การเร่งแซง ซึ่งในปัจจุบันรถยนต์ FCEV
						ได้ถูกพัฒนาให้สามารถชาร์จไฟจากแหล่งพลังงานภายนอก เพื่อเก็บไว้ในแบตเตอรี่ได้ด้วยเช่นกัน
					</span>
					<a
						href="https://evat.or.th/ev-information/ev-technology/index"
						target="_blank"
						className="text-[#0D6EFD] text-sm mt-5"
					>
						ที่มา: สมาคมยานยนต์ไฟฟ้าไทย
					</a>
				</div>
			</div>

			{/* image how do hybrid electric cars work*/}
			<div className="px-[1.5rem] mt-[40px] mb-14 text-center flex flex-col items-center ">
				<div className="grid grid-cols-1 lg:grid-cols-2 max-w-[1400px]">
					<div>
						<img
							src={image_fcev_car}
							alt="How do hybrid electric cars work"
							className="sm:w-[440px] sm:h-[278px]"
						/>
						<a href="https://afdc.energy.gov/vehicles/how-do-fuel-cell-electric-cars-work" target="_blank" className="text-[#0D6EFD] text-sm">
            ที่มา: U.S. Department of Energy (DOE)
						</a>
					</div>
					<div>
						<div className="mb-12 md:px-0 mt-[1.23rem] flex flex-col text-left items-start pl-5 lg:h-full lg:justify-center">
							<span className="text-[40px] text-[#333333]">ยานยนต์ไฟฟ้า FCEV ปัจจุบัน</span>

							<div className="mt-[10px]">
								<span className="text-2xl leading-10 text-[#828282]">
									ซึ่งปัจจุบันการใช้งานอาจจะยังไม่แพร่หลายนัก ทั้งเรื่องของต้นทุนการผลิตที่ยัง
									มาตรฐานความปลอดภัย รวมถึงโครงสร้างพื้นฐานที่ยังไม่รองรับ โดยสหรัฐอเมริกา
									ได้วิจัยและพัฒนาให้พลังงานไฮโดรเจนสามารถใช้งานได้อย่างแพร่หลาย โดยตั้งเป้าหมาย “1 1
									1” คือ 1 เหรียญดอลล่าสหรัฐ ต่อ 1 กิโลกรัมไฮโดรเจน ภายใน 1 ทศวรรษ (ที่มา การประชุม
									APEC Automotive Dialogue ครั้งที่ 39 เมื่อเดือนพฤษภาคม 2567)
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ElectricVehicleInfoSectionFCEV;
