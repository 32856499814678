import React from "react";
import image_BEV_mechanism from "../../assets/detailnextgenerationautomotive/image_BEV_mechanism.png";
import image_all_electric_vehicle from "../../assets/detailnextgenerationautomotive/image_all_electric_vehicle.png";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import image_bev_cars from "../../assets/detailnextgenerationautomotive/image_bev_cars.png";
import ImageBgWaveAbstract from "../../assets/detailnextgenerationautomotive/bg_wave_abstract_ev30.jpeg";

const ElectricVehicleInfoSectionBEV = () => {
	return (
		<div className="flex flex-col items-center overflow-hidden">
			{/* title and subtitle */}
			<div className="px-[1.5rem] pt-[3rem] max-w-[1400px]">
				<div className="h-[9px] w-[102px] bg-[#4a6938] mb-5" />
				<div>
					<span className="text-[#333333] text-[38px] mb-[20px] ">BEV : </span>
					<span className="text-[#828282] text-[38px] mb-[20px] ">Battery Electric Vehicle</span>
				</div>
				<div className="md:px-0 mt-[1.23rem]">
					<span className="text-[#333333] text-2xl leading-10">
						คือยานพาหนะที่
						<span className="text-[#339F00] ">ขับเคลื่อนโดยมอเตอร์ไฟฟ้า</span>
						แทนการใช้เครื่องยนต์ที่มีการเผาไหม้แบบสันดาปโดยรถยนต์ BEV
						จะใช้พลังจากไฟฟ้าแทนการใช้มันน้ำหรือพลังงานอื่นๆโดยระบบรถไฟฟ้าจะเก็บพลังงานเอาไว้ในแบตเตอรี่ที่สามารถชาร์จได้และแปลงพลังงานจากแบตเตอรี่มาใช้ในการขับเคลื่อนรถ
						อนึ่งรถยนต์
					</span>
				</div>
			</div>

			{/* image working principle of PHEV engine*/}
			<div className="px-[1.5rem] pt-[4rem] text-center flex flex-col items-center">
				<img src={image_BEV_mechanism} alt="Working principle of PHEV engine" className="w-[428px] h-[215px]" />
				<div className="md:px-0 mt-[1.23rem] ">
					<div className="lg:w-[900px] m-w-full flex flex-col text-center items-center">
						<span className="text-[40px] text-[#333333]">กลไกลของ BEV</span>
						<span className="text-[#828282] text-2xl leading-10">
							BEVไม่ต้องมีกลไกลอะไรที่มากเหมือนขับเคลื่อนอย่างเช่นรถยนต์ที่ใช้น้ำมันซึ่งต้องใช้
							การจุดระเบิดเผาไหม้ในการขับเคลื่อนทำให้เครื่องยนต์เงียบและ
							<span className="text-[#339F00]">ไม่มีไอเสีย</span>จากการเผาผลาญ
							พลังงานรวมทั้งจุดเด่นของมอเตอร์ไฟฟ้าที่มีแรงบิดตั้งแต่ออกตัว(รอบต่ำ) ทำให้{" "}
							<span className="text-[#339F00]">อัตราเร่งช่วงแรก</span>
							สูงกว่ารถยนต์ทั่วไปมากดังรูป
						</span>
					</div>
				</div>
			</div>

			{/* image how do hybrid electric cars work*/}
			<div className="px-[1.5rem] mt-[40px] text-center flex flex-col items-center ">
				<div className="grid grid-cols-1 lg:grid-cols-2 max-w-[1400px]">
					<div>
						<img
							src={image_all_electric_vehicle}
							alt="How do hybrid electric cars work"
							className="sm:w-[440px] sm:h-[278px]"
						/>
						<a
							href="https://afdc.energy.gov/vehicles/how-do-hybrid-electric-cars-work"
							className="text-[#0D6EFD] text-sm"
              target="_blank"
						>
							ที่มา: U.S. Department of Energy (DOE)
						</a>
					</div>
					<div>
						<div className="mb-12 md:px-0 mt-[1.23rem] flex flex-col text-left items-start pl-5 lg:h-full lg:justify-center">
							<span className="text-[40px] text-[#333333]">ข้อได้เปรียบของรถยนต์ BEV</span>
							<div className="flex flex-row">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="72"
									height="73"
									viewBox="0 0 72 73"
									fill="none"
								>
									<g clip-path="url(#clip0_596_2941)">
										<path
											d="M0 4.99639C0 4.99639 10.883 -2.51288 22.7754 4.04364C27.3263 6.55167 29.247 11.8735 27.5994 16.569C28.107 17.9165 28.5048 19.3085 28.7958 20.6887C29.9802 19.4302 31.3636 18.2845 32.9489 17.3169C32.0345 13.7345 33.5574 9.84922 36.9387 7.95855C46.3226 2.71096 56.4041 8.77774 56.4041 8.77774C54.2013 14.3073 50.3124 19.638 45.0995 22.5527C42.2585 24.1406 38.904 23.8616 36.3747 22.1668C31.7703 24.7758 29.6863 29.5633 29.6863 31.0384H23.7491C23.7491 26.3459 23.2682 22.9355 22.5646 20.377C21.6562 17.9106 19.0468 14.1559 18.9993 14.1203L19.02 14.0936C14.0654 8.12773 7.42159 7.2937 7.42159 7.2937C13.8902 14.6842 16.3275 17.682 17.7257 23.5707C15.9297 23.5945 14.1099 23.2116 12.4297 22.2855C5.82149 18.6437 2.96863 10.2618 0 4.99639ZM68.9762 39.9902L39.9222 72.5917H8.9059C3.99578 72.5917 0 68.5966 0 63.6874V42.9108C0 38.0016 3.99578 34.0065 8.9059 34.0065H38.1677C41.549 34.0065 44.5176 35.8141 46.1504 38.515L55.7005 28.0258C57.3036 26.2598 59.5033 25.2239 61.8901 25.1141C64.3006 24.9984 66.5627 25.8265 68.3291 27.4352C71.933 30.7208 72.2239 36.3543 68.9762 39.9902ZM64.3303 31.822C63.7396 31.2848 62.9736 31.0176 62.1691 31.0444C61.3676 31.0829 60.6284 31.4272 60.0941 32.0209L46.9519 46.4577C45.809 49.6335 42.9769 52.0703 39.4888 52.569L24.1677 54.7564L23.3275 48.8796L38.6487 46.6921C40.3111 46.4577 41.5579 45.0152 41.5579 43.3382C41.5579 41.4683 40.035 39.9457 38.1648 39.9457H8.9059C7.26722 39.9457 5.93727 41.2754 5.93727 42.9138V63.6904C5.93727 65.3288 7.26722 66.6585 8.9059 66.6585H37.2623L64.5441 36.0397C65.6365 34.8168 65.5386 32.9261 64.3303 31.822Z"
											fill="url(#paint0_linear_596_2941)"
										/>
									</g>
									<defs>
										<linearGradient
											id="paint0_linear_596_2941"
											x1="35.617"
											y1="1.35767"
											x2="35.617"
											y2="72.5917"
											gradientUnits="userSpaceOnUse"
										>
											<stop stop-color="#96C93D" />
											<stop offset="1" stop-color="#00B09B" />
										</linearGradient>
										<clipPath id="clip0_596_2941">
											<rect
												width="71.234"
												height="72"
												fill="white"
												transform="translate(0 0.974609)"
											/>
										</clipPath>
									</defs>
								</svg>
								<div className="">
									<span className="text-2xl leading-10">
										“ จากข้อได้เปรียบของรถยนต์ BEV ที่ <br />
										<span className="text-[#339F00]">ไม่ปลดปล่อยมลพิษ</span>
										ขณะใช้งาน “
									</span>
								</div>
							</div>
							<div className="mt-[10px]">
								<span className="text-2xl leading-10 text-[#828282]">
									ทำให้หลายประเทศทั่วโลกได้มีการผลักดันการใช้งานรถยนต์ BEV
									อย่างแพร่หลายเพื่อให้บรรลุเป้าหมายความเป็นกลางทางคาร์บอน (Carbon neutrality) และ
									การปล่อยก๊าซเรือนกระจกสุทธิเป็นศูนย์ (Net Zero Emissions) ภายในระยะเวลาที่ตั้งไว้
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* block show bev car */}
			<div className="flex flex-col w-full items-center mt-14 justify-center h-[420px] relative px-[24px]">
				<p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">
					รถยนต์ <span className="text-[#339F00]"> BEV</span>
				</p>
				<div className="bg-[#333333] rounded-full flex justify-center items-center h-[52px] w-[52px] mt-[10px] cursor-pointer z-10">
					<ArrowDownwardRoundedIcon className="text-white" sx={{ width: 34, height: 34 }} />
				</div>

				<div className="absolute h-[260px] w-full overflow-hidden flex flex-col top-1/2 -translate-y-1/2 ">
					<img
						src={image_bev_cars}
						alt="bev cars"
						className="h-[310px] w-auto self-end absolute left-[65%] object-contain sm:object-left-top sm:top-[150px] sm:object-cover sm:left-[50%] sm:-translate-y-1/2 "
					/>
				</div>

				<div
					className="w-full h-[420px] absolute bg-no-repeat z-0 opacity-60 !bg-[length:630px_630px] bg-[center] hidden md:block"
					style={{
						backgroundImage: `url(${ImageBgWaveAbstract}`,
						backgroundSize: "cover",
						backgroundPosition: "center top -120px",
						WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 100%),
                linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
						WebkitMaskComposite: "multiply",
						maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(180deg, transparent 0%, black 0%, black 80%, transparent 100%),
                    linear-gradient(-180deg, transparent 0%, black 0%, black 80%, transparent 100%)
                `,
						maskComposite: "intersect",
					}}
				></div>

				<div
					className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60 md:hidden"
					style={{
						backgroundImage: `url(${ImageBgWaveAbstract}`,
						backgroundSize: "cover",
						WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 80%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
						WebkitMaskComposite: "multiply",
						maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 80%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
						maskComposite: "intersect",
					}}
				></div>
			</div>
		</div>
	);
};

export default ElectricVehicleInfoSectionBEV;
