import { useRoutes } from "react-router-dom";

// layouts
import MainLayout from "../layouts";
import ComparisonLayout from "../layouts/ComparisonLayout";

// pages
import {
  ComparisonPage,
  ComparisonTablePage,
  StickerPage,
  AboutUsPage,
  AboutEcoSticker,
  AnnouncementWithAPI,
  AutomotiveEventWithAPI,
  Announcement,
  AutomotiveEvent,
  EcoStickerPage,
  WhatIsEcoSticker,
  WhatIsTIS,
  EcoStickerBenefit,
  EcoStickerAbility,
  EcoStickerSafety,
  WhatIsTax,
  WhatIsInternationalStandard,
  CarDetailV2,
} from "../pages";
import NotFound from "../components/NotFound";
import DetailLayout from "src/layouts/DetailLayout";
import KnowingEcoSticker from "src/pages/Articles/KnowingEcoSticker";
import FlowReuestEcoSticker from "src/pages/Articles/FlowReuestEcoSticker";
import MobileApplication from "src/pages/Articles/MobileApplication";
import NewsEcoSticker from "src/pages/Articles/NewsEcoSticker";
import MainNextGenerationAutomotivePage from "src/pages/MainNextGenerationAutomotivePage";
import ElectricCarVehicleContent from "src/pages/DetailNextGenerationAutomotivePage/ElectricCarVehicleContent";
import SupportMeasureOverview from "src/pages/DetailNextGenerationAutomotivePage/SupportMeasureOverview";
import SupportMeasureDetails from "src/pages/DetailNextGenerationAutomotivePage/SupportMeasureDetails";
import SupportMeasureInfo from "src/pages/DetailNextGenerationAutomotivePage/SupportMeasureInfo";
import ManagementSupport from "src/pages/DetailNextGenerationAutomotivePage/ManagementSupport";
import ElectricVehicleBattery from "src/pages/DetailNextGenerationAutomotivePage/ElectricVehicleBattery";
import ChargingConnectorTypes from "src/pages/DetailNextGenerationAutomotivePage/ChargingConnectorTypes";
import ElectricVehicleInfoSectionHEV from "src/pages/DetailNextGenerationAutomotivePage/ElectricVehicleInfoSectionHEV";
import ElectricVehicleInfoSectionPHEV from "src/pages/DetailNextGenerationAutomotivePage/ElectricVehicleInfoSectionPHEV";
import ElectricVehicleInfoSectionBEV from "src/pages/DetailNextGenerationAutomotivePage/ElectricVehicleInfoSectionBEV"
import HeaderSectionMobileApplicationInfo from "src/pages/DetailNextGenerationAutomotivePage/HeaderSectionMobileApplicationInfo";

// ----------------------------------------------------------------------

const Router = () => {
	return useRoutes([
		{
			element: <MainLayout />,
			children: [
				{
					element: <ComparisonLayout />,
					children: [
						{ index: true, element: <ComparisonPage /> },
						{ path: "car-comparison-table", element: <ComparisonTablePage /> },
					],
				},
				{
					path: "article",
					children: [
						{ index: true, element: <AboutEcoSticker /> },
						{ path: "news-eco-sticker", element: <NewsEcoSticker /> },
						{ path: "knowing-eco-sticker", element: <KnowingEcoSticker /> },
						{ path: "what-is-tis", element: <WhatIsTIS /> },
						{ path: "eco-sticker-benefit", element: <EcoStickerBenefit /> },
						{ path: "eco-sticker-safety", element: <EcoStickerSafety /> },
						{ path: "eco-sticker-ability", element: <EcoStickerAbility /> },
						{ path: "what-is-tax", element: <WhatIsTax /> },
						{ path: "what-is-international-standard", element: <WhatIsInternationalStandard /> },
						// new articla
						{ path: "flow-request-eco", element: <FlowReuestEcoSticker /> },
						{ path: "mobile-application", element: <MobileApplication /> },
					],
				},
				{ path: "sticker", element: <StickerPage /> },
				{ path: "about-us", element: <AboutUsPage /> },
				{
					path: "next-generation-automotive",
					children: [
						{ index: true, element: <MainNextGenerationAutomotivePage /> },
						{ path: "electric-car-vehicle", element: <ElectricCarVehicleContent /> },
						{path:"management-support-standard",element:<ManagementSupport />},
						{path:"electric-vehicle-battery",element:<ElectricVehicleBattery />},
            {
              path: "charging-connector-types",
              element: <ChargingConnectorTypes />,
            },
            {path: "dev-electric-vehicle-info-section-hev",element: <ElectricVehicleInfoSectionHEV />},
						{path:"electric-vehicle-info-section-phev",element:<ElectricVehicleInfoSectionPHEV />},
            {path: "electric-vehicle-info", element: <ElectricVehicleInfoSectionBEV />},
						{ path: "dev-support-measure-Info", element: <SupportMeasureInfo /> },
						{ path: "header-section-mobile-app", element: <HeaderSectionMobileApplicationInfo /> },
            
					],
				},

        // { path: 'article', element: <MainPage /> },
        // {
        // 	path: "news",
        // 	children: [
        // 		{ index: true, element: <Announcement /> },
        // 		{ path: "event/:id", element: <AutomotiveEventWithAPI /> },
        // 	],
        // },
        {
          path: "news",
          children: [
            { index: true, element: <AnnouncementWithAPI /> },
            { path: "event/:id", element: <AutomotiveEventWithAPI /> },
          ],
        },
        // { path: 'detail/:id', element: <CarDetail /> },
        { path: "sticker-search", element: <EcoStickerPage /> },
        {
          path: "*",
          element: <NotFound code="404" title="ไม่พบหน้า" />,
        },
      ],
    },
    {
      path: "detail/",
      element: <DetailLayout />,
      children: [{ path: ":id", element: <CarDetailV2 /> }],
    },
  ]);
};

export default Router;
