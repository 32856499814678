import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import styled from "./Navbar.module.scss";
import Logo from "../../assets/logo_eco.png";
import {  MENU_LIST_RIGHT } from "../../configs/menuConfig";
import classNames from "classnames";
import MenuMobile from "./MenuMobile";

const Navbar = () => {
	return (
		<div className={styled.navbarContainer}>
			<NavbarItem to="/" className={styled.navbarItemCenter}>
				<img src={Logo} className={styled.navbarItemLogo} />
			</NavbarItem>
			{/* <div className={classNames(styled.navbarItemSide, styled.left)}>
				{MENU_LIST_LEFT.map((item, key) => {
					return (
						<NavbarItem key={key} to={item.path} className={styled.navbarItem}>
							{item.title}
						</NavbarItem>
					);
				})}
			</div> */}

			<div className={styled.navbarItemSide}>
				{MENU_LIST_RIGHT.map((item, key) => {
					return (
						<NavbarItem key={key} to={item.path} className={styled.navbarItem}>
							{item.title}
						</NavbarItem>
					);
				})}
			</div>
		</div>
	);
};

export const NavbarSm = () => {
	const [open, setOpen] = useState(false);

	const location = useLocation();

	useEffect(() => {
		if (open) {
			setOpen(false);
		}
	}, [location]);

	useEffect(() => {
		let elem = document.getElementsByTagName("body");
		if (open) {
			elem[0].classList.add("on-modale-open");
		} else {
			elem[0].classList.remove("on-modale-open");
		}
	}, [open]);

	return (
		<div className={styled.navbarContainer}>
			<div className={classNames(styled.navbarItemSide, styled.sm)}>
				<div className={styled.navbarMenu} onClick={() => setOpen(true)}>
					<i className="fa-solid fa-bars-staggered"></i>
				</div>
			</div>
			<NavbarItem to="/" className={classNames(styled.navbarItemCenter, styled.sm)}>
				<img src={Logo} className={styled.navbarItemLogo} />
			</NavbarItem>
			<div className={styled.navbarItemSide}></div>
			<MenuMobile open={open} handleClose={setOpen} />
		</div>
	);
};

const NavbarItem = ({ children, to, className }) => {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });
	return (
		<Link to={to} className={classNames(className, { [styled.active]: match })}>
			{children}
		</Link>
	);
};
export default Navbar;
