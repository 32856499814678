import React from "react";
import img_feature_mobile_1 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/feature_mobile_1.png";
import img_feature_mobile_2 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/feature_mobile_2.png";
import img_feature_mobile_3 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/feature_mobile_3.png";
import iphone_mockup_feature from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/iphone_mockup_feature.png";
import icon_car from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_car.png";
import icon_plug from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_plug.png";
import icon_truck_loading from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_truck_loading.png";
import icon_gas_pump from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_gas_pump.png";
import icon_balance_scale from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_balance_scale.png";
import icon_search from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_search.png";
import icon_map from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/icon_map.png";
import bg_banner_oie from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_banner_oie.jpg";
import os_and_qrcode from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/os_and_qrcode.png";
import iphone_mock_eco from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/iphone_mock_eco.png";
import bg_card_feature_compare_1 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_card_feature_compare_1.png";
import bg_card_feature_compare_2 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_card_feature_compare_2.png";
import bg_card_feature_compare_3 from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_card_feature_compare_3.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import styles from "./HeaderSectionMobileApplicationInfo.module.scss";
import { ButtonAndroid, ButtonIos } from "./components/ButtonStore";
import qr_code from "../../assets/detailnextgenerationautomotive/mobileApplicationInfo/qr_code.png";
const HeaderSectionMobileApplicationInfo = () => {
  return (
    <div className="flex flex-col items-center w-full">
      {/* Banner */}
      <div className={styles.responsiveBg}>
        <div
          className="relative grid grid-cols-10 w-full max-w-[1400px] z-50
                    md:grid-cols-1
                "
        >
          <div className="col-span-6 flex flex-col items-center self-center pt-[40px] md:pt-[100px] md:col-span-1 sm:px-[24px]">
            <p className="text-[88px] text-[#333333] text-center font-semibold sm:text-[64px]">
              ECO Sticker OIE
            </p>
            <div className=" max-h-[110px] mt-[37px] flex gap-4 justify-center items-center">
              <div className="flex flex-col gap-4">
                <ButtonAndroid
                  onClick={() => {
                    window.open(
                      "https://car.go.th/application/google-play-store",
                      "_blank"
                    );
                  }}
                />
                <ButtonIos
                  onClick={() => {
                    window.open(
                      "https://car.go.th/application/apple-store",
                      "_blank"
                    );
                  }}
                />
              </div>
              <img
                src={qr_code}
                className="h-full max-h-[130px]"
                alt="qr_code"
              />
            </div>
          </div>
          <div className="col-span-4 self-end md:col-span-1 md:flex md:justify-center ">
            <img
              src={iphone_mock_eco}
              className="h-full max-h-[600px] md:mt-[50px]  sm:hidden"
              alt="iphone_eco"
            />
          </div>
        </div>

        <div className="absolute inset-0 bg-cover bg-center h-full w-full bg-gradient-to-b from-[#ffffff3b] to-[#ffffff00] z-10"></div>
      </div>

      <div className="flex flex-col items-center w-full bg-gradient-to-b from-[#ffffff] to-[#f8f9fa] z-50">
        {/* Our Feature */}
        <div className="flex flex-col gap-[6px] w-full max-w-[1400px] mt-[64px] mb-[72px] px-[24px] md:px-0">
          <p className="text-[54px] md:px-[24px] px-0">ฟีเจอร์ของเรา</p>

          <div className="flex gap-[48px] justify-center">
            <Splide
              className="w-full items-center flex justify-center [&>div]:w-full"
              options={{
                drag: false,
                focus: 0,
                perPage: 3,
                gap: 46,
                breakpoints: {
                  768: {
                    perPage: 1,
                    drag: "free",
                    // drag: true,
                    padding: "2rem"
                  },
                  488: {
                    perPage: 1,
                    drag: "free",
                    padding: "1.5rem"
                  }
                },
                omitEnd: true,
                arrows: false,
                width: "100%"
              }}
            >
              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="h-[570px] max-w-[436px] w-full  rounded-[16px] relative overflow-hidden p-[20px_30px]">
                  <div className="relative z-20">
                    <p className={`text-[26px] text-[#5C5C5C]`}>ค้นหาข้อมูล</p>
                    <p className={`text-[18px] text-[#5C5C5C] mt-[6px]`}>
                      ด้วยฐานข้อมูลที่มากกว่า 100 รายการ
                    </p>
                  </div>
                  <div className="absolute inset-0 bg-cover bg-center h-full w-full bg-gradient-to-b from-[#ffffff92] to-[#ffffff00] z-10"></div>
                  <div
                    className="absolute inset-0 bg-cover bg-center "
                    style={{ backgroundImage: `url(${img_feature_mobile_1})` }}
                  ></div>
                </div>
              </SplideSlide>

              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="h-[570px] max-w-[436px] w-full  rounded-[16px] relative overflow-hidden p-[20px_30px]">
                  <div className="relative z-20">
                    <p className={`text-[26px] text-[#5C5C5C]`}>
                      เปรียบเทียบยานยนต์
                    </p>
                    <p className={`text-[18px] text-[#5C5C5C] mt-[6px]`}>
                      รวมถึงมอเตอร์ไซต์และยางล้อ
                    </p>
                  </div>
                  <div className="absolute inset-0 bg-cover bg-center h-full w-full bg-gradient-to-b from-[#ffffff92] to-[#ffffff00] z-10"></div>
                  <div
                    className="absolute inset-0 bg-cover bg-center "
                    style={{ backgroundImage: `url(${img_feature_mobile_2})` }}
                  ></div>
                </div>
              </SplideSlide>

              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="h-[570px] max-w-[436px] w-full  rounded-[16px] relative overflow-hidden p-[20px_30px]">
                  <div className="relative z-20">
                    <p className={`text-[26px] text-white`}>ข้อมูลสติกเกอร์</p>
                    <p className={`text-[18px] text-white mt-[6px]`}>
                      ดูข้อมูลสติกเกอร์ได้ทุกที่ทุกเวลา
                    </p>
                  </div>
                  <div className="absolute inset-0 bg-cover bg-center h-full w-full bg-gradient-to-b from-[#ffffff92] to-[#ffffff00] z-10"></div>
                  <div
                    className="absolute inset-0 bg-cover bg-center "
                    style={{ backgroundImage: `url(${img_feature_mobile_3})` }}
                  ></div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>

        {/* Over all Application */}
        <div className="flex flex-col items-center w-full max-w-[1400px] my-[40px] px-[24px]">
          <p className="text-[#339F00] text-[30px] text-center">
            ภาพรวม Application
          </p>
          <p className="text-[18px] text-[#333333] text-center mt-[6px]">
            เกี่ยวกับแอปพลิเคชัน Eco sticker
          </p>
          <p className="text-[14px] text-[#333333] text-center max-w-[768px] mt-[12px]">
            แอปพลิเคชันค้นหารถยนต์และจักณยานยนต์ใหม่ที่จำหน่ายในประเทศ
            สำหรับผู้บริโภค
            <br />
            เพื่อใช้ในการเปรียบเทียบคุณสมบัติของรถยนต์หรือรถจักรยานยนต์ในด้าน{" "}
            <span className="text-[#339F00]">สะอาด ประหยัด ปลอดภัย</span> <br />
            เพื่อประกอบการตัดสินใจซื้อ โดยค้นหาตามยี่ห้อ รุ่น ประเภทรถ ราคา
            และค้นหาคุณสมบัติต่างตามที่ต้องการ
          </p>

          <div className="w-full max-w-[690px] grid grid-cols-2 gap-[46px] mt-[72px] items-center sm:grid-cols-1">
            <div className="sm:px-[50px]">
              <p className="inline-block text-[18px] text-white text-center self-start bg-[#333333] p-[8px_16px] rounded-[100px]">
                คุณสมบัติแอปพลิเคชัน
              </p>

              <div className="mt-[26px] flex flex-col gap-[26px]">
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_car} className="w-[24px]" />
                  ข้อมูลรถยนต์ จักยานยนต์ ยางล่าสุด
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_plug} className="w-[24px]" />
                  ข้อมูลยานยนต์ไฟฟ้ายุคใหม่
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_truck_loading} className="w-[24px]" />
                  ข้อมูลผู้ผลิตและนำเข้า
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_gas_pump} className="w-[24px]" />
                  อัตราการปล่อยก๊าซและการใช้พลังงาน
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_balance_scale} className="w-[24px]" />
                  การเปรียบเทียบยานยนต์และยางล้อ
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_search} className="w-[24px]" />
                  ดูข้อมูล Eco sticker
                </p>
                <p className="text-[18px] flex items-center gap-[12px]">
                  <img src={icon_map} className="w-[24px]" />
                  ดาวน์โหลดโบรชัวร์
                </p>
              </div>
            </div>

            <div className="flex justify-end sm:justify-center">
              <img
                src={iphone_mockup_feature}
                style={{
                  filter: "drop-shadow(5px 16px 47px rgba(79,130,79,0.3))"
                }}
                className="max-h-[590px] w-auto "
              />
            </div>
          </div>
        </div>

        {/* Comparison Function */}
        <div className="flex flex-col w-full max-w-[1400px]  mt-[40px] mb-[16px] px-[24px] md:px-0">
          <p className="text-[#339F00] text-[30px] md:px-[24px] px-0">
            ฟังก์ชันการเปรียบเทียบ
          </p>
          <p className="text-[#333333] text-[30px] md:px-[24px] px-0">
            ผู้ใช้สามารถตัดสินใจเลือกยานพาหนะในอุดมคติได้อย่างง่ายดาย
          </p>

          <div className="flex w-full mt-[16px] justify-center">
            <Splide
              className="w-full items-center flex justify-center [&>div]:w-full"
              options={{
                drag: false,
                focus: 0,
                perPage: 3,
                gap: 32,
                breakpoints: {
                  768: {
                    perPage: 1,
                    drag: "free",
                    padding: "2rem"
                  },
                  488: {
                    perPage: 1,
                    drag: "free",
                    padding: "1.5rem"
                  }
                },
                omitEnd: true,
                arrows: false,
                width: "100%"
              }}
            >
              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="flex flex-col max-w-[446px] w-full">
                  <img
                    src={bg_card_feature_compare_1}
                    className="h-[344px] w-full object-cover rounded-[16px]"
                  />
                  <p className="text-center text-[18px] text-[#333333] mt-[16px]">
                    รวบรวมข้อมูลที่จำเป็นทั้งหมดไว้ในที่เดียวไม่จำเป็นต้อง
                    <br />
                    ค้นหาจากหลายแหล่งหรือหลายไซต์
                  </p>
                </div>
              </SplideSlide>

              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="flex flex-col max-w-[446px] w-full">
                  <img
                    src={bg_card_feature_compare_2}
                    className="h-[344px] w-full object-cover rounded-[16px]"
                  />
                  <p className="text-center text-[18px] text-[#333333] mt-[16px]">
                    รวบรวมข้อมูลที่จำเป็นทั้งหมดไว้ในที่เดียวไม่จำเป็นต้อง
                    <br />
                    ค้นหาจากหลายแหล่งหรือหลายไซต์
                  </p>
                </div>
              </SplideSlide>

              <SplideSlide
                className="flex justify-center w-fit cursor-pointer"
                style={{ padding: "2rem 0" }}
              >
                <div className="flex flex-col max-w-[446px] w-full">
                  <img
                    src={bg_card_feature_compare_3}
                    className="h-[344px] w-full object-cover rounded-[16px]"
                  />
                  <p className="text-center text-[18px] text-[#333333] mt-[16px]">
                    รวบรวมข้อมูลที่จำเป็นทั้งหมดไว้ในที่เดียวไม่จำเป็นต้อง
                    <br />
                    ค้นหาจากหลายแหล่งหรือหลายไซต์
                  </p>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSectionMobileApplicationInfo;
