import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grid } from "@mui/material";
import CardChargingTypes from "../../components/SupportMeasureOverview/CardChargingTypes";
import CardConnectorTypes from "../../components/SupportMeasureOverview/CardConnectorTypes";
import img_header from "../../assets/detailnextgenerationautomotive/landing_page_header.png";
import img_connector_types_mobile from "../../assets/detailnextgenerationautomotive/image_connector_types_mobile.png";
import image_charging from "../../assets/detailnextgenerationautomotive/image_charging.png";
import image_dc_charging from "../../assets/detailnextgenerationautomotive/image_dc_charging.png";
import icon_bolt from "../../assets/detailnextgenerationautomotive/bolt.png";
import icon_age from "../../assets/detailnextgenerationautomotive/age.png";
import icon_charging_time from "../../assets/detailnextgenerationautomotive/battery_three_quarters.png";
import connector_type_1 from "../../assets/detailnextgenerationautomotive/connector_type_1.png";
import connector_type_2 from "../../assets/detailnextgenerationautomotive/connector_type_2.png";
import connector_type_ccs from "../../assets/detailnextgenerationautomotive/connector_type_ccs.png";
import connector_type_dc from "../../assets/detailnextgenerationautomotive/connector_type_dc.png";
import connector_type_supercharge from "../../assets/detailnextgenerationautomotive/connector_type_supercharge.png";
import styles from "./ChargingConnectorTypes.module.scss";

const ChargingConnectorTypes = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center">
      {/* header */}
      <div
        className="w-full h-[443px] bg-cover bg-center flex justify-center relative "
        style={{
          backgroundImage: `url(${img_header})`,
        }}
      >
        <p className="text-[72px] sm:text-[62px] text-center font-light text-white absolute top-[98px] sm:top-[5px]">
          เทคโนโลยีการชาร์จและ
          <br className="hidden sm:inline" />
          ประเภทหัวชาร์จ
        </p>
      </div>
      <div className="flex flex-col h-fit bg-white py-[56px] px-[48px] rounded-2xl gap-2 shadow-lg max-w-[1400px] z-20 mt-[-150px] md:m-3 md:mt-[-150px]  ">
        <div className="flex items-center gap-5 md:flex-col md:items-start pl-[16px] sm:pl-[48px]">
          <p className="text-[18px] font-normal text-[#333333] ">
            เทคโนโลยียานยนต์ไฟฟ้า
          </p>
          <div className="w-[73px] h-[2px] bg-[#333333]"></div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-1 md:gap-[33px] pt-[8px] px-[15px]">
          <p className="text-[38px] ">
            เทคโนโลยี<span className="text-[#339F00]">การชาร์จ</span>
            และ <span className="text-[#339F00]">ประเภทหัวชาร์จ</span>
          </p>
          <p className="text-[18px] text-[#828282]">
            การชาร์จรถยนต์ไฟฟ้าขึ้นอยู่กับความเร็วในการชาร์จและแหล่งพลังงานที่ใช้
            การเลือกประเภทหัวชาร์จจะขึ้นอยู่กับยี่ห้อรถยนต์ไฟฟ้าและมาตรฐานที่ใช้ในประเทศต่าง
            ๆ
          </p>
        </div>
      </div>
      {/* End header */}

      {/* component charging technology */}
      <div className=" flex flex-col w-full mt-[56px] items-start md:!px-6 max-w-[1400px]">
        <div className="h-[9px] w-[102px] bg-[#559B2D] mb-5" />
        <p className="text-[#333333] text-[38px] pb-[32px]">
          เทคโนโลยีการชาร์จ (Charging)
        </p>
        <div className="  md:px-0 mb-[56px]">
          <p className="text-[#333333]  text-[18px]">
            การชาร์จรถยนต์ไฟฟ้า (EV) มีหลายประเภท
            ขึ้นอยู่กับความเร็วในการชาร์จและแหล่งพลังงานที่ใช้
            โดยสามารถแบ่งออกเป็น 3 ประเภทหลัก ได้แก่ การชาร์จระดับ 1 (Level 1),
            การชาร์จระดับ 2 (Level 2) และ การชาร์จเร็วแบบกระแสตรง (DC Fast
            Charging)
          </p>
        </div>
        <Splide
          className="w-full items-center flex justify-center [&>div]:w-full"
          options={{
            drag: false,
            focus: 0,
            perPage: 3,
            breakpoints: {
              768: {
                perPage: 2,
                drag: "free",
                gap: "4rem",
                padding: "1.5rem",
              },
              488: {
                perPage: 1,
                drag: "free",
                padding: "2.5rem",
              },
            },
            omitEnd: true,
            arrows: false,
          }}
        >
          <SplideSlide
            className="flex justify-center w-fit"
            style={{ padding: "2rem 0" }}
          >
            <CardChargingTypes
              titleTh={"การชาร์กระดับ 1"}
              titleEn={"(Level 1 Charging)"}
              subTitle={
                "ใช้ในกรณีฉุกเฉิน โดยใช้สาย Emergency Charger ที่มากับรถ"
              }
              img={image_charging}
              imgDetail={{
                iconBolt: icon_bolt,
                iconAge: icon_age,
                iconChargingTime: icon_charging_time,
              }}
              detail={{
                voltageDetail:
                  "เป็นการใช้ไฟฟ้ากระแสสลับ (AC) จากปลั๊กไฟบ้านทั่วไป",
                electricalPowerDetail: "ประมาณ 1-2 กิโลวัตต์ (kW)",
                chargingTime:
                  "ชาร์จเต็มในเวลา 8-20 ชั่วโมง ขึ้นอยู่กับขนาดแบตเตอรี่",
                strengthDetail: "none",
                weaknessDetail: "none",
              }}
            />
          </SplideSlide>
          <SplideSlide
            className="flex justify-center w-fit"
            style={{ padding: "2rem 0" }}
          >
            <CardChargingTypes
              titleTh={"การชาร์กระดับ 2"}
              titleEn={"(Level 2 Charging)"}
              subTitle={
                "นิยมใช้ในบ้านที่มีการติดตั้งที่ชาร์จแบบ Wallbox และสถานที่สาธารณะ เช่น ห้างสรรพสินค้า สถานที่ทำงาน"
              }
              img={image_charging}
              imgDetail={{
                iconBolt: icon_bolt,
                iconAge: icon_age,
                iconChargingTime: icon_charging_time,
              }}
              detail={{
                voltageDetail: "เป็นการใช้ไฟฟ้ากระแสสลับ (AC) ผ่าน Wallbox",
                electricalPowerDetail:
                  "3.3-22 กิโลวัตต์ (kW) (โดยทั่วไปอยู่ที่ประมาณ 6.6 - 11 kW)",
                chargingTime:
                  "ชาร์จเต็มภายใน 4-8 ชั่วโมง ขึ้นอยู่กับขนาดแบตเตอรี่",
                strengthDetail:
                  "ชาร์จได้เร็วกว่า Level 1 อย่างมาก เหมาะกับการใช้งานในชีวิตประจำวัน",
                weaknessDetail: "ต้องติดตั้ง Wallbox ซึ่งอาจมี ค่าใช้จ่ายเพิ่ม",
              }}
            />
          </SplideSlide>
          <SplideSlide
            className="flex justify-center w-fit"
            style={{ padding: "2rem 0" }}
          >
            <CardChargingTypes
              titleTh={"การชาร์จเร็วแบบกระแสตรง"}
              titleEn={"(DC Fast Charging)"}
              subTitle={
                "มักใช้ในสถานีชาร์จเร็วที่ติดตั้งตามทางหลวงหรือสถานีบริการน้ำมัน เพื่อรองรับผู้ขับขี่ที่ต้องการชาร์จ ระหว่างการเดินทาง"
              }
              img={image_dc_charging}
              imgDetail={{
                iconBolt: icon_bolt,
                iconAge: icon_age,
                iconChargingTime: icon_charging_time,
              }}
              detail={{
                voltageDetail:
                  "400-800 โวลต์ (V) ขึ้นไป – ใช้ไฟฟ้ากระแสตรง (DC)",
                electricalPowerDetail: "50-350 กิโลวัตต์ (kW) หรือมากกว่า",
                chargingTime:
                  "สามารถชาร์จ 80% ของแบตเตอรี่ ได้ภายในเวลา 15-45 นาที ขึ้นอยู่ กับระบบการชาร์จและขนาดของแบตเตอรี่",
                strengthDetail: "ความเร็วในการชาร์จสูงมาก",
                weaknessDetail:
                  "การชาร์จเร็วสามารถทำให้แบตเตอรี่ร้อนขึ้น และอาจส่งผลต่ออายุการใช้งานของแบตเตอรี่ในระยะยาว",
              }}
            />
          </SplideSlide>
        </Splide>
      </div>

      {/* component connector types */}
      <div className={styles.backgroundChargingType}>
        <div className="h-[9px] w-[102px] bg-[#559B2D] mb-5" />

        <p className="text-[#333333] text-[38px] mb-[20px] break-words">
          ประเภทหัวชาร์จ (Connector Types)
        </p>
        <div className="mb-12 md:px-0">
          <p className="text-[#333333]  text-[18px]">
            การเลือกประเภทหัวชาร์จจะขึ้นอยู่กับยี่ห้อรถยนต์ไฟฟ้าและมาตรฐานที่ใช้ในประเทศต่าง
            ๆ เช่น
          </p>
        </div>
        <Grid container justifyContent="center" gap={4}>
          <Grid item xs={12} lg={3}>
            <CardConnectorTypes
              title="Type 1"
              des="มาตรฐานในอเมริกาและญี่ปุ่น สำหรับการชาร์จระดับ 1 และ 2 (AC)"
              img={connector_type_1}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CardConnectorTypes
              title="Type 2"
              des="มาตรฐานในยุโรปและเอเชีย สำหรับการชาร์จระดับ 2 (AC) หรือใช้ในการชาร์จ DC ด้วย"
              img={connector_type_2}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CardConnectorTypes
              title="CHAdeMO"
              des="มาตรฐานการชาร์จเร็วแบบ DC ที่ใช้โดยรถยนต์ญี่ปุ่นบางรุ่น เช่น Nissan Leaf"
              img={connector_type_ccs}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CardConnectorTypes
              title="CCS"
              subTitle="(Combined Charging System)"
              des="รองรับทั้งการชาร์จ AC และ DC นิยมใช้ในยุโรปและอเมริกา"
              img={connector_type_dc}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CardConnectorTypes
              title="Tesla"
              subTitle="Supercharge"
              des="ใช้เฉพาะกับรถยนต์ของ Tesla แต่บางสถานีก็รองรับหัวชาร์จแบบ CCS"
              img={connector_type_supercharge}
            />
          </Grid>
        </Grid>
      </div>

      {/* component example connector types */}
      <div className="p-[40px] flex flex-col w-full  items-center md:!px-6">
        <p className="text-[#333333] text-[38px] pb-[32px]">
          ซึ่งแต่ละแบบมีลักษณะตามรูปข้างล่าง 
        </p>
        <div className="px-[60px] md:px-0">
          <div className="text-center leading-9 text-[18px] max-w-[600px] md:mx-16 mb-4">
            ทั้งนี้ ในประเทศไทยนั้นได้เลือกใช้หัวชาร์จแบบ Type II
            ซึ่งอ้างอิงตามมาตรฐาน IEC 62196
            และได้ถูกประกาศใช้ให้เป็นมาตรฐานอุตสาหกรรม มอก. 2749 เล่ม 2-2559 
          </div>
        </div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <img
              src={img_connector_types_mobile}
              alt="example connector types"
              className=""
            />
          </Grid>
        </Grid>

        <div className=" max-w-[700px] md:w-full mt-5">
          <a target="_blank" href="https://www.wallbox.in.th/%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A0%E0%B8%97%E0%B8%AB%E0%B8%B1%E0%B8%A7%E0%B8%8A%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%88%E0%B9%83%E0%B8%99%E0%B8%A3%E0%B8%96/" className="text-[#0D6EFD] underline text-[16px] text-center break-words">
            ที่มารูป: เว็ปไซต์ทำความรู้จักประเภทหัวชาร์จในรถแต่ละรุ่น ประเภทหัวชาร์จในรถ
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChargingConnectorTypes;
