import React from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const CardTimeline = ({ hiddenArrow, title, detail }) => {
  return (
    <div className="w-[190px] h-[130px] shadow-[0px_8px_16px_0px_#00000026] rounded-2xl p-4 relative gap-2">
      <div className="text-[22px] text-[#339F00] leading-[28px]">{title}</div>
      <div className="text-[14px] leading-[24px] text-[#333333] mr-4">{detail}</div>
      {!hiddenArrow && (
        <div className="arrow flex justify-center items-center bg-[#333333] w-[38px] h-[38px] rounded-[6px] absolute top-[50%] bottom-[50%] -translate-y-[50%] -right-5">
          <ArrowForwardIosRoundedIcon sx={{ color: "#fff" }} />
        </div>
      )}
    </div>
  );
};

export default CardTimeline;
