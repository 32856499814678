import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const CardEV = ({ titleTh, titleEn, detail, img, subTitle }) => {
  return (
    <div className="h-[350px] w-[280px] flex items-center px-8 pb-8 rounded-2xl flex-col shadow-[0px_8px_16px_0px_#00000026]">
      <div className="-translate-y-8 flex justify-center flex-col items-center text-center">
        <div className="w-[100px] h-[90px] flex justify-center items-center">
          <img src={img} alt="Passenger Car" />
        </div>

        <div className="gap-1 flex flex-col justify-center">
          <div className="text-xl leading-6">{titleTh}</div>
          <div className="text-base leading-4 text-nowrap">
            {titleEn}{" "}
            <span className="text-[#828282] text-nowrap">{subTitle}</span>
          </div>
        </div>
      </div>

      <div className="flex h-full flex-col items-center justify-between">
        <div className="flex flex-col items-center leading-5 text-base gap-1">
          <div className="text-[#339F00]">ขนาดแบตเตอรี่</div>
          {detail?.batterySizeLess && <div>{detail?.batterySizeLess}</div>}
          {detail?.batterSubsidyLess && <div>{detail?.batterSubsidyLess}</div>}
          {detail?.batterySizeMore && <div>{detail?.batterySizeMore}</div>}
          {detail?.batterSubsidyMore && <div>{detail?.batterSubsidyMore}</div>}
        </div>

        <div className="flex flex-col leading-5 text-base gap-2">
          {detail?.exciseTax === "none" ? (
            <div className="flex gap-3 items-center">
              <CloseIcon className="text-[#828282] !text-[14px]" />
              <div>ไม่ลดภาษีสรรพสามิต</div>
            </div>
          ) : (
            <div className="flex gap-3 items-center">
              <CheckIcon className="text-[#339F00] !text-[14px]" />
              <div>ลดภาษีสรรพสามิต {detail?.exciseTax} %</div>
            </div>
          )}

          {detail?.customTax === "none" ? (
            <div className="flex gap-3 items-center">
              <CloseIcon className="text-[#828282] !text-[14px]" />
              <div>ไม่ลดภาษีศุลกากร</div>
            </div>
          ) : (
            <div className="flex gap-3 items-center">
              <CheckIcon className="text-[#2339F00] !text-[14px]" />
              <div>ลดภาษีศุลกากร {detail?.customTax} %</div>
            </div>
          )}

          <div className="text-[#339F00]">{detail?.price}</div>
        </div>
      </div>
    </div>
  );
};

export default CardEV;
