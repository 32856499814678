import React from "react";
import ImageBgWaveAbstract from "../../assets/detailnextgenerationautomotive/bg_wave_abstract_ev30.jpeg";
import CarImageHev from "../../assets/detailnextgenerationautomotive/car_mg_sv_hev_d.png";
import MotorImage from "../../assets/detailnextgenerationautomotive/mg_sv_hev_d.png";
import PassengerCar from "../../assets/detailnextgenerationautomotive/passenger_car.png";
import CheckIcon from "@mui/icons-material/Check";
import { Divider } from "@mui/material";
import PickupCar from "../../assets/detailnextgenerationautomotive/pickup_car.png";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const SupportMeasureDetails = ({ CardFisrtSlide, CardSecondSlide }) => {
	return (
		<div className="w-full bg-[#F8F9FA] overflow-hidden ">
			{/* Section 1 */}
			<div className="bg-gradient-to-b from-[#ffffff00] to-[#ffffff] mt-[96px]">
				<div className="flex flex-col w-full items-center justify-center h-[420px] relative px-[24px]">
					<p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">
						มอเตอร์ไซค์ไฟฟ้า ที่เข้าร่วม<p className="text-[38px] text-[#339F00] mb-0">EV3.0</p>
					</p>

					<div className="bg-[#333333] rounded-full flex justify-center items-center h-[52px] w-[52px] mt-[10px] cursor-pointer z-10">
						<ArrowDownwardRoundedIcon className="text-white" sx={{ width: 34, height: 34 }} />
					</div>

					<div className="absolute h-[260px] w-full overflow-hidden flex top-1/2 -translate-y-1/2 ">
						{/* left-[55%] h-[335px] object-contain */}
						<img
							src={MotorImage}
							alt="motorcycle"
							className="h-[335px] w-auto absolute left-[70%] object-contain md:left-[calc(70%+50px)] sm:left-[85%] sm:object-cover sm:object-left-top sm:top-1/2 sm:-translate-y-1/2 "
						/>
					</div>

					{/* <div className="absolute h-[260px] w-full overflow-hidden flex top-1/2 -translate-y-1/2 bg-red-200"> */}
					{/* left-[55%] h-[335px] object-contain */}
					{/* <img src={MotorImage} alt='motorcycle' className="h-[335px] w-auto absolute left-[55%] top-[-10px] object-contain sm:left-[50%] sm:object-cover sm:object-left sm:top-1/2 sm:-translate-y-1/2 bg-amber-100"/> */}
					{/* </div> */}

					<div
						className="w-full h-[420px] absolute bg-no-repeat z-0 opacity-60 !bg-[length:630px_630px] bg-[center] hidden md:block"
						style={{
							backgroundImage: `url(${ImageBgWaveAbstract}`,
							backgroundSize: "cover",
							backgroundPosition: "center top -200px",
							WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
							WebkitMaskComposite: "multiply",
							maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                    linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
							maskComposite: "intersect",
						}}
					></div>

					<div
						className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60 md:hidden"
						style={{
							backgroundImage: `url(${ImageBgWaveAbstract}`,
							backgroundSize: "cover",
							WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
							WebkitMaskComposite: "multiply",
							maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
							maskComposite: "intersect",
						}}
					></div>
				</div>
				{CardFisrtSlide && CardFisrtSlide}
			</div>

			{/* Section 2 */}
			<div className="mt-[48px] flex flex-col w-full  items-center bg-white pt-[104px] pb-[56px]">
				<div className="flex flex-col px-[24px] w-full max-w-[1600px] items-center">
					<p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">มาตรการ EV3.5</p>
					<p className="text-[18px] text-[#828282] text-center mt-[24px]">
						จากความสำเร็จของมาตรการ
						<br /> EV3.0 ทางรัฐบาลได้มีการออกมาตรการส่งเสริมการใช้ยานยนต์ไฟฟ้าระยะที่ 2 หรือที่เรียกว่า
						EV3.5
						<br className="sm:hidden" /> ซึ่งจะมีผลในปี 2024 – 2027 
					</p>
				</div>

				<div className="w-full flex justify-center mt-[50px] mb-[28px]">
					<Splide
						className="w-full items-center flex justify-center [&>div]:w-full"
						options={{
							drag: false,
							focus: 0,
							perPage: 2,
							breakpoints: {
								768: {
									perPage: 1,
									drag: "free",
									padding: "9rem",
								},
								488: {
									perPage: 1,
									drag: "free",
									padding: "1.5rem",
								},
							},
							omitEnd: true,
							arrows: false,
							width: 786,
						}}
					>
						<SplideSlide className="flex justify-center w-fit" style={{ padding: "2rem 0" }}>
							<div className="flex flex-col w-[364px] min-h-[350px] bg-white rounded-[16px] px-[24px] pb-[32px] shadow-[0px_8px_16px_0px_#00000026]">
								<div className="flex flex-col items-center mt-[-28px]">
									<img src={PassengerCar} alt="passenger car" className="h-[80px]" />
									<p className="text-[18px] text-[#333333] text-center mt-[12px]">
										รถยนต์นั่ง<p className="text-[16px]">(Passenger Car)</p>
									</p>
								</div>
								<div className=" h-full mt-[24px] grid grid-cols-2">
									<div className="flex flex-col justify-between pr-[12px]">
										<div>
											<p className="text-[12px] text-[#339F00] text-center mb-[4px]">
												ขนาดแบตเตอรี่
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												น้อยกว่า 50 kWh
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												เงินอุดหนุน 50,000 บาท
											</p>
										</div>

										<div>
											<ul className="text-[14px] text-[#333333] flex flex-col gap-[10px]">
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ภาษีสรรพสามิต 2 %
												</li>
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ลดภาษีศุลกากร 40 %
												</li>
												<li className="text-[12px] text-[#339F00] text-center">
													เงินอุดหนุน 50,000 บาท
												</li>
											</ul>
										</div>
									</div>

									<div className="relative flex flex-col justify-between pl-[12px]">
										<Divider orientation="vertical" className="absolute left-0" />
										<div>
											<p className="text-[12px] text-[#339F00] text-center mb-[4px]">
												ขนาดแบตเตอรี่
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												มากกว่ากว่า 50 kWh
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												เงินอุดหนุน 100,000 บาท
											</p>
										</div>

										<div>
											<ul className="text-[14px] text-[#333333] flex flex-col gap-[10px]">
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ภาษีสรรพสามิต 2 %
												</li>
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ลดภาษีศุลกากร 20 %
												</li>
												<li className="text-[12px] text-[#339F00] text-center">
													สำหรับราคาไม่เกิน 2 ล้านบาท
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</SplideSlide>

						<SplideSlide className="flex justify-center w-fit" style={{ padding: "2rem 0" }}>
							<div className="flex flex-col w-[364px] min-h-[350px] bg-white rounded-[16px] px-[24px] pb-[32px] shadow-[0px_8px_16px_0px_#00000026]">
								<div className="flex flex-col items-center mt-[-28px]">
									<img src={PickupCar} alt="passenger car" className="h-[80px]" />
									<p className="text-[18px] text-[#333333] text-center mt-[12px]">
										รถยนต์กระบะ
										<p className="text-[16px]">
											(Pickup Car) <span className="text-[#828282]">สำหรับที่ผลิตในประเทศ</span>
										</p>
									</p>
								</div>

								<div className="h-full mt-[24px] flex flex-col items-center w-full">
									<div className="flex flex-col justify-between h-full w-full">
										<div>
											<p className="text-[12px] text-[#339F00] text-center mb-[4px]">
												ขนาดแบตเตอรี่
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												มากกกว่า 30 kWh
											</p>
											<p className="text-[14px] text-[#333333] text-center mb-[4px]">
												เงินอุดหนุน 150,000 บาท
											</p>
										</div>

										<div className=" self-center">
											<ul className="text-[14px] text-[#333333] flex flex-col gap-[10px]">
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ภาษีสรรพสามิต 0 %
												</li>
												<li>
													<CheckIcon className="text-[#339F00] !text-[14px] mr-[5px]" />
													ไม่ลดภาษีศุลกากร
												</li>
												<li className="text-[12px] text-[#339F00] text-center">
													สำหรับราคาไม่เกิน 2 ล้านบาท
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</SplideSlide>
					</Splide>
				</div>
				<div className="px-[24px]">
					<p className="text-[18px] text-[#333333] text-center mt-[24px]">
						ทั้งนี้ ผู้ประกอบการต้องมีการผลิตชดเชยในประเทศไทย ตามจำนวนที่นำเข้ามาในช่วงปี 2024 – 2025
						ตามสัดส่วนที่กำหนด คือ
						<br />
						จะต้องผลิตชดเชยในอัตรา 1:2 หากมีการผลิตในปี 2026 และในอัตรา 1:3 หากผลิตในปี 2027
						<br />
						รวมทั้งจำเป็นจะต้องมีการใช้ชิ้นส่วนที่สำคัญของยานยนต์ไฟฟ้าในประเทศภายในปีที่กำหนด เช่นเดียวกับ
						EV3.0
					</p>
				</div>
			</div>

			{/* Section 3 */}
			<div className="bg-gradient-to-b from-[#ffffff00] to-[#ffffff] ">
				<div className="flex flex-col w-full items-center justify-center h-[420px] relative px-[24px]">
					<p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">
                    รถยนต์ ES ที่เข้าร่วม<p className="text-[38px] text-[#339F00] mb-0">EV3.5</p>
					</p>
					<div className="bg-[#333333] rounded-full flex justify-center items-center h-[52px] w-[52px] mt-[10px] cursor-pointer z-10">
						<ArrowDownwardRoundedIcon className="text-white" sx={{ width: 34, height: 34 }} />
					</div>

					<div className="absolute h-[260px] w-full overflow-hidden flex flex-col top-1/2 -translate-y-1/2 ">
						<img
							src={CarImageHev}
							alt="motorcycle"
							className="h-[310px] w-auto self-end absolute left-[55%] object-contain md:left-[calc(55%+60px)] sm:object-left-top sm:top-[150px] sm:object-cover sm:left-[50%] sm:-translate-y-1/2 "
						/>
					</div>

					<div
						className="w-full h-[420px] absolute bg-no-repeat z-0 opacity-60 !bg-[length:630px_630px] bg-[center] hidden md:block"
						style={{
							backgroundImage: `url(${ImageBgWaveAbstract}`,
							backgroundSize: "cover",
							backgroundPosition: "center top -200px",
							WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
							WebkitMaskComposite: "multiply",
							maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 40%, black 0%, transparent 100%),
                    linear-gradient(-90deg, transparent 0%, black 40%, black 0%, transparent 100%)
                `,
							maskComposite: "intersect",
						}}
					></div>

					<div
						className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60 md:hidden"
						style={{
							backgroundImage: `url(${ImageBgWaveAbstract}`,
							backgroundSize: "cover",
							WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
							WebkitMaskComposite: "multiply",
							maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
							maskComposite: "intersect",
						}}
					></div>
				</div>

				{CardSecondSlide && CardSecondSlide}
			</div>
		</div>
	);
};

export default SupportMeasureDetails;
