import React from "react";
import CarES from "../../assets/detailnextgenerationautomotive/car_es.png";
import Background from "../../assets/detailnextgenerationautomotive/bg_support_measure.jpeg";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

const JoinESCar = () => {
  return (
    <div className="bg-gradient-to-b from-[#ffffff00] to-[#ffffff] mt-[96px] ">
      <div className="flex flex-col w-full items-center justify-center h-[420px] relative">
        <div className="flex flex-col w-full items-center justify-center gap-[10px]">
          <p className="text-[38px] text-[#333333] mb-0 leading-[48px] text-center z-10">
            รถยนต์ ES ที่เข้าร่วม
            <p className="text-[38px] text-[#339F00] mb-0">EV3.0</p>
          </p>

          <div className="bg-[#333333] rounded-full flex justify-center items-center h-[52px] w-[52px] z-50 cursor-pointer">
            <ArrowDownwardRoundedIcon
              className="text-white"
              sx={{ width: 34, height: 34 }}
            />
          </div>
        </div>

        <div className="absolute h-[260px] w-full overflow-hidden flex flex-col top-1/2 -translate-y-1/2 ">
          <img
            src={CarES}
            alt="motorcycle"
            className="w-[630px] self-end absolute left-[55%]"
          />
        </div>

        <div
          className="w-full h-[420px] absolute bg-no-repeat bg-center z-0 opacity-60"
          style={{
            backgroundImage: `url(${Background}`,
            backgroundSize: "cover",
            WebkitMaskImage: `
                linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
            WebkitMaskComposite: "multiply",
            maskImage: `
                    linear-gradient(180deg, transparent 0%, black 0%, transparent 40%),
                    linear-gradient(90deg, transparent 0%, black 20%, black 80%, transparent 100%)
                `,
            maskComposite: "intersect"
          }}
        ></div>
      </div>
    </div>
  );
};

export default JoinESCar;
