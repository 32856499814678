import {  Divider } from "@mui/material";
import React from "react";
import Background from "../../assets/detailnextgenerationautomotive/bg_support_measure.jpeg";
import PublicIcon from "@mui/icons-material/Public";

const MeasuresPromoteEV = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 0, 0), rgb(255, 255, 255)),url(${Background})`
      }}
      className=" w-full bg-no-repeat bg-center bg-cover relative flex py-20 flex-col items-center  "
    >
      <div className="w-full max-w-[1600px] flex flex-col items-center gap-[56px]">
      <div className="leading-[57.6px] text-[38px] text-center sm:mx-8">
        มาตรการส่งเสริมการใช้ยานยนต์ไฟฟ้า
      </div>
      <div className="text-center leading-9 text-[18px] max-w-[1000px] md:mx-16">
        จากแผนปฏิบัติการดังกล่าว จะเห็นได้ว่าการใช้ยานยนต์ไฟฟ้าในภาคการขนส่ง
        จะสามารถช่วยลดการปล่อย GHG ได้ถึงร้อยละ 62
        ของเป้าหมายทั้งหมดในภาคการขนส่ง
        ซึ่งประเทศไทยได้มีการดำเนินมาตรการส่งเสริมการใช้ยานยนต์ไฟฟ้า
        หรือที่เรียกว่า EV3.0 และ EV 3.5 ซึ่งจะให้สิทธิประโยชน์ต่าง ๆ
        กับผู้บริโภค เช่น การให้เงินอุดหนุน การลดอัตราภาษีสรรพสามิต
        การลดอัตราภาษีนำเข้า เป็นต้น
      </div>

      <div className="flex gap-8 md:mx-6">
        <div
          className="w-full max-w-[330px] h-[260px] sm:h-[300px] rounded-2xl bg-white"
          style={{
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)"
          }}
        >
          <div className="py-3 px-6 flex justify-center items-center flex-col gap-1 h-1/2">
            <div className="w-[41px] h-[40px] min-w-[41px] min-h-[40px] border border-[#828282] rounded-md flex justify-center items-center">
              <PublicIcon sx={{ width: 24, height: 24 }} />
            </div>
            <div className="text-[18px] text-[#333333] leading-7 font-[400] text-center">
              มาตรการ EV3.0
            </div>
            <div className="font-[400] text-[#828282] text-[14px] leading-[21px] text-center">
              ทดลองตลาดในประเทศไทยได้ในช่วงปี 2022 – 2023
            </div>
          </div>

          <Divider color="#828282" />

          <div className="py-3 px-6 flex justify-center items-center flex-col gap-1 h-1/2">
            <div className="text-center leading-5 text-[16px] text-[#333333]">
              อนุญาตให้ผู้ประกอบการนำเข้ารถยนต์ไฟฟ้าจากต่างประเทศ
              เพื่อเข้ามาทดลองตลาดในประเทศไทยได้ในช่วงปี{" "}
              <span className="text-[#339F00]">2022 – 2023</span>
            </div>
          </div>
        </div>

        <div
          className="w-full max-w-[330px] h-[260px] sm:h-[300px] rounded-2xl bg-[#333333]"
          style={{
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)"
          }}
        >
          <div className="py-3 px-6 flex justify-center items-center flex-col gap-1 h-1/2">
            <div className="w-[41px] h-[40px] min-w-[41px] min-h-[40px] border border-[#fff] rounded-md flex justify-center items-center">
              <PublicIcon sx={{ width: 24, height: 24, color: "#fff" }} />
            </div>
            <div className="text-[18px] text-[#fff] leading-7 font-[400] text-center">
              มาตรการ EV3.5
            </div>
            <div className="font-[400] text-[#fff] text-[14px] leading-[21px] text-center">
              ส่งเสริมการใช้ยานยนต์ไฟฟ้ามีผลในปี 2024 – 2027
            </div>
          </div>

          <Divider color="#fff" />

          <div className="flex px-6 py-8 justify-center items-center flex-col gap-1 h-1/2">
            <div className="text-center leading-5 text-[16px] text-[#fff]">
              มาตรการส่งเสริมการใช้ยานยนต์ไฟฟ้าระยะที่ 2 หรือที่เรียกว่า EV3.5
              ซึ่งจะมีผลในปี <span className="text-[#339F00]">2024 – 2027</span>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default MeasuresPromoteEV;
