import React from "react";
import SupportMeasureDetails from "./SupportMeasureDetails";
import SupportMeasureOverview from "./SupportMeasureOverview";
import SupportMeasureInfo from "./SupportMeasureInfo";
import CardCarSlideItem from "src/components/GlobalComponents/CardCarSlide";

const ManagementSupport = () => {
	return (
		<div>
			<SupportMeasureInfo />
			<SupportMeasureOverview />
			<CardCarSlideItem isEv car_type="ev3" />
			<SupportMeasureDetails
				CardFisrtSlide={<CardCarSlideItem isEv car_type="motor" />}
				CardSecondSlide={<CardCarSlideItem isEv car_type="ev3.5" />}
			/>
		</div>
	);
};

export default ManagementSupport;
